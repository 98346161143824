import Api from '../../Api'

class PermissionsApi extends Api {
    getRolesPermissionsList(token, conditions) {
        return this.http.post('/permission/list',
            {
                "limit": 10000,
                "offset": 0,
                "conditions": conditions || null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    createRole(token, name, permission_group_id_list) {
        return this.http.post('/permission/role/create',
            {
                "name": name,
                "permission_group_id": permission_group_id_list
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    editRole(token, role_id, name, permission_group_id_list) {
        return this.http.post('/permission/role/update',
            {
                "role_id": role_id,
                "name": name,
                "permission_group_id": permission_group_id_list
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    deleteRole(token, role_id) {
        return this.http.delete('/permission/role/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data:  {
                    "role_id": role_id
                }
            }
        )
    }
    getPermissionsUsers(token, conditions) {
        return this.http.post('/permission/user/list',
            {
                "limit": 10000,
                "offset": 0,
                "conditions": conditions || null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getRolesList(token) {
        return this.http.post('/permission/role',
            {
                "conditions": null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getPermissions(token) {
        return this.http.get('/settings/user/permission',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getUsersList(token) {
        return this.http.post('/settings/user',
            {
                "conditions": null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    deleteUser(token, user_id) {
        return this.http.delete('/settings/user/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data: {
                    "user_id": user_id
                }
            }
        )
    }

    createUser(token, email, password, password_confirmation, first_name, last_name, country, shipments_per_month, phone, company_id, invite_code, hear_about_us, role_id) {
        return this.http.post('/settings/user/create',
            {
                "email": email,
                "password": password,
                "password_confirmation": password_confirmation,
                "first_name": first_name,
                "last_name": last_name,
                "country": Number(country),
                "shipments_per_month": Number(shipments_per_month),
                "phone": String(phone),
                "company_id": Number(company_id),
                "invite_code": invite_code,
                "hear_about_us": hear_about_us,
                "role_id": role_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    editUser(token, user_id, email, first_name, last_name, country, shipments_per_month, phone, company_id, invite_code, hear_about_us, role_id) {
        return this.http.post('/settings/user/edit',
            {
                "user_id": user_id,
                "email": email,
                "first_name": first_name,
                "last_name": last_name,
                "country": Number(country),
                "shipments_per_month": Number(shipments_per_month),
                "phone": String(phone),
                "company_id": Number(company_id),
                "invite_code": invite_code,
                "hear_about_us": hear_about_us,
                "role_id": role_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getVersion(token) {
        return this.http.get('version/user',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getVersionsList(token) {
        return this.http.post('version/list',
            {

            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    editUserVersion(token, user_id, version_id) {
        return this.http.post('/version/user/edit',
            {
                "user_id": user_id,
                "version_id": version_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getUsersVersions(token) {
        return this.http.get('version/users',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getUserShops(token) {
        return this.http.get('/shop-permissions/user-shops-list',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    editUserShops(token, user_id, shop_ids) {
        return this.http.post('/shop-permissions/user-shops-edit',
            {
                "user_id": user_id,
                "shop_id": shop_ids
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    blockUser(token, user_id) {
        return this.http.post('/users/ban/set',
            {
                "user_id": user_id,
                "is_on": 1
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    unblockUser(token, user_id, password) {
        return this.http.post('/users/ban/set',
            {
                "user_id": user_id,
                "is_on": 0,
                "password": password
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    isBlockedUser(token, user_id) {
        return this.http.post('/users/ban/get',
            {
                "user_id": user_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default  new PermissionsApi()