import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_DIM_METHOD,
    SET_DIM_WEIGHT,
    SET_DIM_WEIGHT_LIST
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRuleById, getShippingRulesDetails, getShippingRulesList} from "./shippingRulesReducer"
import OrdersApi from "../../api/OrdersApi/OrdersApi"

const initialState = {
    DimWeightList: [],
    DimMethod: null
}

const DimWeightReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DIM_WEIGHT_LIST:
            return {
                ...state,
                DimWeightList: action.list.sort((a, b) => a.delivery_service_type_id - b.delivery_service_type_id)
            }
        case SET_DIM_WEIGHT:
            return {
                ...state,
                DimWeight: action.payload
            }
        case SET_DIM_METHOD:
            return {
                ...state,
                DimMethod: action.payload
            }
        default:
            return state
    }
}

export const setDimWeightList = (list) => ({type: SET_DIM_WEIGHT_LIST, list})
export const setDimWeight = (payload) => ({type: SET_DIM_WEIGHT, payload})
export const setDimMethod = (payload) => ({type: SET_DIM_METHOD, payload})

export const getDimWeightList = () => (dispatch, getState) => {
    SettingsApi.getDimWeightList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setDimWeightList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const updateDimWeight = (delivery_service_type_id, dim_weight_coef) => (dispatch, getState) => {
    SettingsApi.updateDimWeight(getState().auth.AuthToken, delivery_service_type_id, dim_weight_coef).then(response => {
        dispatch(getDimWeightList())
        CheckAndNotify(response)
        dispatch(setDimWeightList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getDimWeightRateCarrier = (order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                                        package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
                                        delivery_service_type_id, setService) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        OrdersApi.getDimWeightRateCarrier(getState().auth.AuthToken, order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
            package_dimension_width, package_dimension_height, package_dimension_unit_of_measure,
            delivery_service_type_id).then(response => {
            dispatch(setDimWeight(response.data.data))
            setService(response.data.data.service_type_id)
            CheckAndNotify(response)
            resolve(response)
        }).catch(error => {
            if (error) {
                dispatch(setDimWeight(null))
                dispatch(LogOutOnAuthError(error))
                /*
                            CheckAndNotifyErrors(error)
                */
            }
            reject(error)
        })
    })
}

export const getDimWeightRateCarrierAuto = (order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                                        package_dimension_width, package_dimension_height, package_dimension_unit_of_measure, setService) => (dispatch, getState) => {
    OrdersApi.getDimWeightRateCarrierAuto(getState().auth.AuthToken, order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
        package_dimension_width, package_dimension_height, package_dimension_unit_of_measure).then(response => {
            if (response.data.data.shiping_rule_id) {
                (async () => {
                    let shipping_rule_data = await dispatch(getShippingRuleById(response.data.data.shiping_rule_id))
                    let carrier_id = shipping_rule_data.data.data.data.find(r => r.condition_object === "Country").statements.find(s => s.statement_name === "Carrier").statement_value
                    if (carrier_id) {
                        let dim_weight = await dispatch(getDimWeightRateCarrier(order_id, package_weight_value, package_weight_unit_of_measure, package_dimension_length,
                            package_dimension_width, package_dimension_height, package_dimension_unit_of_measure, Number(carrier_id), setService))
                    }
                })()
            } else {
                dispatch(setDimWeight(response.data.data))
                setService(response.data.data.service_type_id)
            }
            dispatch(setDimWeight(response.data.data))
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(setDimWeight(null))
            dispatch(LogOutOnAuthError(error))
/*
            CheckAndNotifyErrors(error)
*/
        }
    })
}

export default DimWeightReducer
