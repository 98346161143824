import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST, SET_CARRIERS_COEFFICIENTS_ERROR_LOG_LIST,
    SET_CARRIERS_COEFFICIENTS_LOG_LIST,
    SET_CARRIERS_RATES_LIST,
    SET_RATES_LIST,
    SET_UPLOADING_FILES_LIST,
    SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"
import {setCarriersCoefficientsLogList} from "./CarriersCoefficients_Log_Reducer"

const initialState = {
    CarriersCoefficients_ErrorLog: []
}

const CarriersCoefficients_ErrorLog_Reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CARRIERS_COEFFICIENTS_ERROR_LOG_LIST:
            return {
                ...state,
                CarriersCoefficients_ErrorLog: action.list
            }
        default:
            return state
    }
}

export const setCarriersCoefficientsErrorLogList = (list) => ({type: SET_CARRIERS_COEFFICIENTS_ERROR_LOG_LIST, list})

export const getCarriersCoefficientsErrorLogList = () => (dispatch, getState) => {
    SettingsApi.getCarriersCoefficientsErrorLogList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setCarriersCoefficientsErrorLogList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default CarriersCoefficients_ErrorLog_Reducer
