import TagApi from '../../api/TagApi/TagApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_TAGS_LIST
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError
} from "../orders/ordersReducer"

const initialState = {
    TagsList: []
}

const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TAGS_LIST:
            return {
                ...state,
                TagsList: action.array
            }
        default:
            return state
    }
}

export const setTagsList = (array) => ({type: SET_TAGS_LIST, array})

export const getTagsList = () => (dispatch, getState) => {
    TagApi.getTags(getState().auth.AuthToken).then(response => {
        dispatch(setTagsList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default tagReducer
