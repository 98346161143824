import {AuthApi} from '../../api'
import {CheckAndNotify, CheckAndNotifyErrors, getShopsList, LogOutOnAuthError} from '../orders/ordersReducer'
import {getCurrentVersion, getCurrentVersionAndCompare} from "../permissions/permissionsReducer"
import {isBlockedUser} from "../settings/UsersReducer"

const USER_ERRORS = 'AUTH/SET-USER-REGISTRATION-ERRORS'
const USER_MESSAGE = 'AUTH/SET-USER-REGISTRATION-MESSAGE'
const SET_USER_REGISTER_ERROR = 'AUTH/SET_USER_REGISTER_ERROR'
const REMOVE_ERROR_MESSAGE = 'AUTH/REMOVE-ERROR-MESSAGE'
const DISABLE_GET_STARTED = 'AUTH/DISABLE-GET-STARTED'
const REMOVE_ALL_ERRORS = 'AUTH/REMOVE-ALL-REGISTER-ERRORS'

const USER_LOGIN_ERRORS = 'AUTH/SET-USER-LOGIN-ERRORS'
const SET_USER_LOGIN_ERROR = 'AUTH/SET_USER_LOGIN_ERROR'
const USER_LOGIN_MESSAGE = 'AUTH/SET-USER-LOGIN-MESSAGE'
const REMOVE_LOGIN_ERROR_MESSAGE = 'AUTH/REMOVE-LOGIN-ERROR-MESSAGE'
const DISABLE_SIGN_IN = 'AUTH/DISABLE-SIGN-IN'

const DISABLE_NEXT_STEP = 'AUTH/DISABLE-NEXT-STEP'
const RESOLVER_FIRST_REQUEST = 'AUTH/RESOLVED-FIRST-REQUEST'
const SET_ONE_ERROR = 'AUTH/SET-ONE-VALIDATION-ERROR'

const DISABLE_NEXT_STEP_MARGIN = 'AUTH/DISABLE-NEXT-STEP-WITH-MARGIN'
const RESOLVER_SECOND_REQUEST = 'AUTH/RESOLVED-SECOND-REQUEST'

const SET_AUTH_TOKEN = 'AUTH/SET-AUTH-TOKEN'
const DELETE_AUTH_TOKEN = 'AUTH/DELETE-AUTH-TOKEN'

const SET_USER_INFO = 'AUTH/SET_USER_INFO'

const SET_USER_IS_BLOCKED = 'AUTH/SET_USER_IS_BLOCKED'

const initialState = {
    userRegisterValidationErrors: [],
    userRegisterMessage: null,
    GetStartedDisable: false,
    userLoginValidationErrors: [],
    userLoginMessage: null,
    SignInDisable: false,
    NextDisable: false,
    FirstRequestResolved: false,
    NextDisableWithMargin: false,
    SecondRequestResolved: false,
    AuthToken: window.localStorage.getItem('AuthDeliKey'),
    UserInfo: JSON.parse(window.localStorage.getItem('UserInfo'))

}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
      case USER_ERRORS:
          return {
              ...state,
              userRegisterValidationErrors: action.errors
          }
      case USER_MESSAGE:
          return {
              ...state,
              userRegisterMessage: action.message
          }
      case SET_USER_REGISTER_ERROR:
          return {
              ...state,
              userRegisterValidationErrors: {...state.userRegisterValidationErrors, [action.field]: [action.error_name]}
          }
      case REMOVE_ERROR_MESSAGE:
          return {
              ...state,
              userRegisterValidationErrors: {...state.userRegisterValidationErrors, [action.messageKey]: null}
          }
      case DISABLE_GET_STARTED:
          return {
              ...state,
              GetStartedDisable: action.boolean
          }
      case USER_LOGIN_ERRORS:
          return {
              ...state,
              userLoginValidationErrors: action.errors
          }
      case SET_USER_LOGIN_ERROR:
          return {
              ...state,
              userLoginValidationErrors: {...state.userLoginValidationErrors, [action.field]: [action.error_name]}
          }
      case USER_LOGIN_MESSAGE:
          return {
              ...state,
              userLoginMessage: action.message
          }
      case REMOVE_LOGIN_ERROR_MESSAGE:
          return {
              ...state,
              userLoginValidationErrors: {...state.userLoginValidationErrors, [action.messageKey]: null}
          }
      case DISABLE_SIGN_IN:
          return {
              ...state,
              SignInDisable: action.boolean
          }
      case REMOVE_ALL_ERRORS:
          return {
              ...state,
              userRegisterValidationErrors: [],
              userRegisterMessage: null
          }
      case DISABLE_NEXT_STEP:
          return {
              ...state,
              NextDisable: action.boolean
          }
      case RESOLVER_FIRST_REQUEST:
          return {
              ...state,
              FirstRequestResolved: !state.FirstRequestResolved
          }
      case SET_ONE_ERROR:
          return {
              ...state.userRegisterValidationErrors,
              userRegisterValidationErrors: {...state.userRegisterValidationErrors, [action.key]: [...action.errors]}
          }
      case DISABLE_NEXT_STEP_MARGIN:
          return {
              ...state,
              NextDisableWithMargin: action.boolean

          }
      case RESOLVER_SECOND_REQUEST:
          return {
              ...state,
              SecondRequestResolved: !state.SecondRequestResolved
          }
      case SET_AUTH_TOKEN:
          return {
              ...state,
              AuthToken: action.token
          }
      case DELETE_AUTH_TOKEN:
          return {
              ...state,
              AuthToken: null
          }
      case SET_USER_INFO:
          return {
              ...state,
              UserInfo: action.info
          }
      case SET_USER_IS_BLOCKED:
          let user_info = Object.assign({}, state.UserInfo)
          user_info.user.blocked = action.is_on
          return {
              ...state,
              UserInfo: user_info
          }
    default:
      return state
  }
}

const SetUserRegisterValidationErrors = (errors) => ({type: USER_ERRORS, errors})
export const SetUserRegisterMessage = (message) => ({type: USER_MESSAGE, message})
export const removeRegisterValidationMessage = (messageKey) => ({type:REMOVE_ERROR_MESSAGE, messageKey})
export const removeAllRegisterErrors = () => ({type:REMOVE_ALL_ERRORS})
export const DisableGetStarted = (boolean) => ({type: DISABLE_GET_STARTED, boolean})
export const pushRegisterValoidationError = (field, error_name) => ({type: SET_USER_REGISTER_ERROR, field, error_name})
export const setUserIsBlocked = (is_on) => ({type: SET_USER_IS_BLOCKED, is_on})


const SetUserLoginValidationErrors = (errors) => ({type: USER_LOGIN_ERRORS, errors})
export const SetUserLoginMessage = (message) => ({type: USER_LOGIN_MESSAGE, message})
export const pushLoginValoidationError = (field, error_name) => ({type: SET_USER_LOGIN_ERROR, field, error_name})
export const removeLoginValidationMessage = (messageKey) => ({type:REMOVE_LOGIN_ERROR_MESSAGE, messageKey})
export const DisableSignIn = (boolean) => ({type: DISABLE_SIGN_IN, boolean})

export const DisableNext = (boolean) => ({type: DISABLE_NEXT_STEP, boolean})
export const RequestFirstResolved = () => ({type: RESOLVER_FIRST_REQUEST})
export const SetOneUserValidationError = (key, errors) => ({type: SET_ONE_ERROR, key, errors})

export const DisableNextWithMargin = (boolean) => ({type: DISABLE_NEXT_STEP_MARGIN, boolean})
export const RequestSecondResolved = () => ({type: RESOLVER_SECOND_REQUEST})

export const SetAuthToken = (token) => ({type: SET_AUTH_TOKEN, token})
const DeleteAuthToken = () => ({type: DELETE_AUTH_TOKEN})

const SetUserInfo = (info) => ({type: SET_USER_INFO, info})

export const Registration = (email, password, password_confirmation, first_name, last_name,
                             country, shipments_per_month, phone, company,
                             invite_code, hear_about_us, accept_term_of_us) => (dispatch) => {
    AuthApi.postRegister(email, password, password_confirmation,
                    first_name, last_name, country,
                    shipments_per_month, phone, company,
                    invite_code, hear_about_us, accept_term_of_us).then(response => {
                        CheckAndNotify(response)
                        dispatch(SetUserRegisterMessage(response.data.message))
                        dispatch(DisableGetStarted(false))
                                                                                    }).catch(error => {
                    if (error) {
                        CheckAndNotifyErrors(error)
                    }
                    if (error.response) {
                        // Request made and server responded
                        dispatch(SetUserRegisterValidationErrors(error.response.data.validation_errors))
                        dispatch(SetUserRegisterMessage(error.response.data.message))
                        dispatch(DisableGetStarted(false))
                    } else if (error.request) {
                        // The request was made but no response was received
                        dispatch(SetUserRegisterMessage('Something is wrong. No server response'))
                        dispatch(DisableGetStarted(false))
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        if (error.message) { dispatch(SetUserRegisterMessage(error.message)) } else { dispatch(SetUserRegisterMessage('Something is wrong')) }
                        dispatch(DisableGetStarted(false))
                    }
                })
    }
export const onFirstStepValidation = (email, password, password_confirmation) => (dispatch) => {
    AuthApi.postRegister(email, password, password_confirmation).then(response => {
        CheckAndNotify(response)
        dispatch(DisableNext(false))
        dispatch(RequestFirstResolved())
    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            if (error.response.data.validation_errors.email) { dispatch(SetOneUserValidationError('email', error.response.data.validation_errors.email)) }
            if (error.response.data.validation_errors.password) { dispatch(SetOneUserValidationError('password', error.response.data.validation_errors.password)) }
                if (error.response.data.validation_errors.password_confirmation) { dispatch(SetOneUserValidationError('password_confirmation', error.response.data.validation_errors.password_confirmation)) }
                if (!error.response.data.validation_errors.email && !error.response.data.validation_errors.password && !error.response.data.validation_errors.password_confirmation) {
                 dispatch(removeAllRegisterErrors())
                }
            dispatch(DisableNext(false))
            dispatch(RequestFirstResolved())
        } else if (error.request) {
            // The request was made but no response was received
            dispatch(SetUserRegisterMessage('Something is wrong. No server response'))
            dispatch(DisableNext(false))
            dispatch(RequestFirstResolved())
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.message) { dispatch(SetUserRegisterMessage(error.message)) } else { dispatch(SetUserRegisterMessage('Something is wrong')) }
            dispatch(DisableNext(false))
            dispatch(RequestFirstResolved())
        }
    })
}

export const onSecondStepValidation = (first_name, last_name,
                                       country, phone, company) => (dispatch) => {
    AuthApi.postRegister('', '', '', first_name, last_name,
        country, '', phone, company).then(response => {
        CheckAndNotify(response)
        dispatch(DisableNextWithMargin(false))
        dispatch(RequestSecondResolved())

    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            if (error.response.data.validation_errors.first_name) { dispatch(SetOneUserValidationError('first_name', error.response.data.validation_errors.first_name)) }
            if (error.response.data.validation_errors.last_name) { dispatch(SetOneUserValidationError('last_name', error.response.data.validation_errors.last_name)) }
            if (error.response.data.validation_errors.country) { dispatch(SetOneUserValidationError('country', error.response.data.validation_errors.country)) }
            if (error.response.data.validation_errors.phone) { dispatch(SetOneUserValidationError('phone', error.response.data.validation_errors.phone)) }
            if (error.response.data.validation_errors.company) { dispatch(SetOneUserValidationError('company', error.response.data.validation_errors.company)) }
            if (!error.response.data.validation_errors.first_name && !error.response.data.validation_errors.last_name && !error.response.data.validation_errors.country && !error.response.data.validation_errors.phone && !error.response.data.validation_errors.company) {
                dispatch(removeAllRegisterErrors())
            }
            dispatch(DisableNextWithMargin(false))
            dispatch(RequestSecondResolved())
        } else if (error.request) {
            // The request was made but no response was received
            dispatch(SetUserRegisterMessage('Something is wrong. No server response'))
            dispatch(DisableNextWithMargin(false))
            dispatch(RequestSecondResolved())
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.message) { dispatch(SetUserRegisterMessage(error.message)) } else { dispatch(SetUserRegisterMessage('Something is wrong')) }
            dispatch(DisableNextWithMargin(false))
            dispatch(RequestSecondResolved())
        }
    })
}

export const onLoginInit = (token) => (dispatch, getState) => {
/*
    dispatch(getShopsList(token))
*/
}

export const Logining = (email, password, app_version) => (dispatch) => {
    AuthApi.postLogin(email, password).then(response => {
        CheckAndNotify(response)
        dispatch(isBlockedUser(response.data.data.user.user_id, response.data.data.token))
        dispatch(SetUserLoginMessage(response.data.message))
        dispatch(SetAuthToken(response.data.data.token))
        window.localStorage.setItem('AuthDeliKey', response.data.data.token)
        dispatch(SetUserInfo(response.data.data))
        window.localStorage.setItem('UserInfo', JSON.stringify(response.data.data))
        dispatch(DisableSignIn(false))
        dispatch(getCurrentVersionAndCompare(app_version))
        /*if (response.data.data.token !== null) {
            setTimeout(dispatch(onLoginInit(response.data.data.token)), 1000)
        }*/
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
        if (error.response) {
            // Request made and server responded
            dispatch(SetUserLoginValidationErrors(error.response.data.validation_errors))
            dispatch(SetUserLoginMessage(error.response.data.message))
            dispatch(DisableSignIn(false))
        } else if (error.request) {
            // The request was made but no response was received
            dispatch(SetUserLoginMessage('Something is wrong. No server response'))
            dispatch(DisableSignIn(false))
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.message) { dispatch(SetUserLoginMessage(error.message)) } else { dispatch(SetUserLoginMessage('Something is wrong')) }
            dispatch(DisableSignIn(false))
        }
    })
}

export const Logout = (token) => (dispatch) => {
    window.localStorage.removeItem('AuthDeliKey')
    dispatch(DeleteAuthToken())
    AuthApi.deleteLogout(token).then(response => {
        CheckAndNotify(response)
        window.localStorage.removeItem('AuthDeliKey')
        dispatch(DeleteAuthToken())
    }).catch(error => {
        window.localStorage.removeItem('AuthDeliKey')
        dispatch(DeleteAuthToken())
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export const ForgotPassword = (email) => (dispatch) => {
    AuthApi.postPasswordForgot(email).then(response => {
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export default authReducer
