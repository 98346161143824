import Api from './Api'
import axios from "axios"

class UpcApi extends Api {
    getDataByUpc(token, upc) {
        return this.http.post('scan-page-view',
            {
                "code": String(upc)
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getReturnPageData(token, upc) {
        return this.http.post('return-page-view',
            {
                "code": String(upc)
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    sendUpcProblemApi(token, order_item_id, order_item_status_id, problem_id, comment, page_id) {
        return this.http.post('order-item/edit-order-item-status',
            {
                "order_item_id": Number(order_item_id),
                "order_item_status_id": Number(order_item_status_id),
                "problem_id": problem_id,
                "comment": comment,
                "page_id": page_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    sendUpcOrderProcessingTime(token, page_id, order_id) {
        return this.http.post('work/order_log',
            {
                "order_id": order_id,
                "page_id": page_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getDataFromScales(token, orderItemId) {
        return this.http.get('storage-scales?order_item_id=' + orderItemId,
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getScalesDataFromLocalhost(token) {
        return axios.create({withCredentials: true,
            headers: {
                "Content-type": this._contentType()
            }
        }).get('http://localhost:8000/',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getCantIdentifyBarcode(token) {
        return this.http.get('storage/cin-barcode',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default new UpcApi()
