export default [
    {
        "weight_lb": 0.063,
        "rate": null
    },
    {
        "weight_lb": 0.125,
        "rate": null
    },
    {
        "weight_lb": 0.188,
        "rate": null
    },
    {
        "weight_lb": 0.250,
        "rate": null
    },
    {
        "weight_lb": 0.313,
        "rate": null
    },
    {
        "weight_lb": 0.375,
        "rate": null
    },
    {
        "weight_lb": 0.438,
        "rate": null
    },
    {
        "weight_lb": 0.500,
        "rate": null
    },
    {
        "weight_lb": 0.563,
        "rate": null
    },
    {
        "weight_lb": 0.625,
        "rate": null
    },
    {
        "weight_lb": 0.688,
        "rate": null
    },
    {
        "weight_lb": 0.750,
        "rate": null
    },
    {
        "weight_lb": 0.813,
        "rate": null
    },
    {
        "weight_lb": 0.875,
        "rate": null
    },
    {
        "weight_lb": 0.938,
        "rate": null
    },
    {
        "weight_lb": 1.000,
        "rate": null
    },
    {
        "weight_lb": 1.063,
        "rate": null
    },
    {
        "weight_lb": 1.125,
        "rate": null
    },
    {
        "weight_lb": 1.188,
        "rate": null
    },
    {
        "weight_lb": 1.250,
        "rate": null
    },
    {
        "weight_lb": 1.313,
        "rate": null
    },
    {
        "weight_lb": 1.375,
        "rate": null
    },
    {
        "weight_lb": 1.438,
        "rate": null
    },
    {
        "weight_lb": 1.500,
        "rate": null
    },
    {
        "weight_lb": 1.563,
        "rate": null
    },
    {
        "weight_lb": 1.625,
        "rate": null
    },
    {
        "weight_lb": 1.688,
        "rate": null
    },
    {
        "weight_lb": 1.750,
        "rate": null
    },
    {
        "weight_lb": 1.813,
        "rate": null
    },
    {
        "weight_lb": 1.875,
        "rate": null
    },
    {
        "weight_lb": 1.938,
        "rate": null
    },
    {
        "weight_lb": 2.000,
        "rate": null
    },
    {
        "weight_lb": 2.063,
        "rate": null
    },
    {
        "weight_lb": 2.125,
        "rate": null
    },
    {
        "weight_lb": 2.188,
        "rate": null
    },
    {
        "weight_lb": 2.250,
        "rate": null
    },
    {
        "weight_lb": 2.313,
        "rate": null
    },
    {
        "weight_lb": 2.375,
        "rate": null
    },
    {
        "weight_lb": 2.438,
        "rate": null
    },
    {
        "weight_lb": 2.500,
        "rate": null
    },
    {
        "weight_lb": 2.563,
        "rate": null
    },
    {
        "weight_lb": 2.625,
        "rate": null
    },
    {
        "weight_lb": 2.688,
        "rate": null
    },
    {
        "weight_lb": 2.750,
        "rate": null
    },
    {
        "weight_lb": 2.813,
        "rate": null
    },
    {
        "weight_lb": 2.875,
        "rate": null
    },
    {
        "weight_lb": 2.938,
        "rate": null
    },
    {
        "weight_lb": 3.000,
        "rate": null
    },
    {
        "weight_lb": 3.063,
        "rate": null
    },
    {
        "weight_lb": 3.125,
        "rate": null
    },
    {
        "weight_lb": 3.188,
        "rate": null
    },
    {
        "weight_lb": 3.250,
        "rate": null
    },
    {
        "weight_lb": 3.313,
        "rate": null
    },
    {
        "weight_lb": 3.375,
        "rate": null
    },
    {
        "weight_lb": 3.438,
        "rate": null
    },
    {
        "weight_lb": 3.500,
        "rate": null
    },
    {
        "weight_lb": 3.563,
        "rate": null
    },
    {
        "weight_lb": 3.625,
        "rate": null
    },
    {
        "weight_lb": 3.688,
        "rate": null
    },
    {
        "weight_lb": 3.750,
        "rate": null
    },
    {
        "weight_lb": 3.813,
        "rate": null
    },
    {
        "weight_lb": 3.875,
        "rate": null
    },
    {
        "weight_lb": 3.938,
        "rate": null
    },
    {
        "weight_lb": 4.000,
        "rate": null
    },
    {
        "weight_lb": 4.063,
        "rate": null
    },
    {
        "weight_lb": 4.125,
        "rate": null
    },
    {
        "weight_lb": 4.188,
        "rate": null
    },
    {
        "weight_lb": 4.250,
        "rate": null
    },
    {
        "weight_lb": 4.313,
        "rate": null
    },
    {
        "weight_lb": 4.400,
        "rate": null
    },
    {
        "weight_lb": 5.000,
        "rate": null
    },
    {
        "weight_lb": 6.000,
        "rate": null
    },
    {
        "weight_lb": 7.000,
        "rate": null
    },
    {
        "weight_lb": 8.000,
        "rate": null
    },
    {
        "weight_lb": 9.000,
        "rate": null
    },
    {
        "weight_lb": 10.000,
        "rate": null
    },
    {
        "weight_lb": 11.000,
        "rate": null
    },
    {
        "weight_lb": 12.000,
        "rate": null
    },
    {
        "weight_lb": 13.000,
        "rate": null
    },
    {
        "weight_lb": 14.000,
        "rate": null
    },
    {
        "weight_lb": 15.000,
        "rate": null
    },
    {
        "weight_lb": 16.000,
        "rate": null
    },
    {
        "weight_lb": 17.000,
        "rate": null
    },
    {
        "weight_lb": 18.000,
        "rate": null
    },
    {
        "weight_lb": 19.000,
        "rate": null
    },
    {
        "weight_lb": 20.000,
        "rate": null
    },
    {
        "weight_lb": 21.000,
        "rate": null
    },
    {
        "weight_lb": 22.000,
        "rate": null
    },
    {
        "weight_lb": 23.000,
        "rate": null
    },
    {
        "weight_lb": 24.000,
        "rate": null
    },
    {
        "weight_lb": 25.000,
        "rate": null
    },
    {
        "weight_lb": 26.000,
        "rate": null
    },
    {
        "weight_lb": 27.000,
        "rate": null
    },
    {
        "weight_lb": 28.000,
        "rate": null
    },
    {
        "weight_lb": 29.000,
        "rate": null
    },
    {
        "weight_lb": 30.000,
        "rate": null
    },
    {
        "weight_lb": 31.000,
        "rate": null
    },
    {
        "weight_lb": 32.000,
        "rate": null
    },
    {
        "weight_lb": 33.000,
        "rate": null
    },
    {
        "weight_lb": 34.000,
        "rate": null
    },
    {
        "weight_lb": 35.000,
        "rate": null
    },
    {
        "weight_lb": 36.000,
        "rate": null
    },
    {
        "weight_lb": 37.000,
        "rate": null
    },
    {
        "weight_lb": 38.000,
        "rate": null
    },
    {
        "weight_lb": 39.000,
        "rate": null
    },
    {
        "weight_lb": 40.000,
        "rate": null
    }
]