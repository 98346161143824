import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST, SET_RATES_LIST, SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"

const initialState = {
    RatesList: ratesList
}

const Rates = (state = initialState, action) => {
    switch (action.type) {
        case SET_RATES_LIST:
            let NewRatesList = [...state.RatesList]
            NewRatesList.find(r => r.weight_lb === action.weight_lb).rate = Number(action.rate)
            return {
                ...state,
                RatesList: NewRatesList

            }
        default:
            return state
    }
}

export const setRatesList = (rate, weight_lb) => ({type: SET_RATES_LIST, rate, weight_lb})


export default Rates
