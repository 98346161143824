import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST,
    SET_CARRIERS_RATES_LIST,
    SET_ORDER_DETAILS_EDIT_SAVE_BUTTON,
    SET_RATES_LIST,
    SET_UPLOADING_FILES_LIST,
    SET_USER_LIST,
    SET_COMPANY_ADDRESS_LIST, SET_COMPANY_SHOP_ADDRESS_LIST, GET_SETTINGS_HS_CODES

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"
import {setContactUsValidationErrors, setInputErrorsList} from "../commonReducer/commonReducer"

const initialState = {
    hsCodesReducer: []
}

const hsCodesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SETTINGS_HS_CODES:
            return {
                ...state,
                SettingsHsCodes: action.list.sort((a, b) => {
                    if (!a.hs_code.trim() && b.hs_code.trim()) return -1
                    if (a.hs_code.trim() && !b.hs_code.trim()) return 1
                    if (!a.hs_code.trim() && !b.hs_code.trim()) return 0
                    return a.hs_code.localeCompare(b.hs_code)
                }).sort((a, b) => {
                    if (a.hs_code.trim() && b.hs_code.trim()) {
                        return a.category_name.localeCompare(b.category_name)
                    }
                })
            }
        default:
            return state
    }
}

export const setSettingsHsCodes = (list) => ({type: GET_SETTINGS_HS_CODES, list})

export const getSettingsHsCodes = () => (dispatch, getState) => {
    SettingsApi.getSettingsHsCodes(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setSettingsHsCodes(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const updateHsCodes = (category_id, hs_code) => (dispatch, getState) => {
    SettingsApi.updateHsCodes(getState().auth.AuthToken, category_id, hs_code).then(response => {
        CheckAndNotify(response)
        dispatch(setSettingsHsCodes(response.data.data))
        dispatch(getSettingsHsCodes())
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
/*
            CheckAndNotifyErrors(error)
*/
        }
    })
}

export default hsCodesReducer
