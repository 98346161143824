
import React from "react"
import {SET_ERROR_COUNT} from "../../constants/constants"

const initialState = {
    AppError: {
        "SameScalesData": {
            count: 0,
            message: null
        },
        "SameScalesParam": {
            message: 0,
            name: null
        }
    }
}

const AppErrorReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ERROR_COUNT:
            if (action.name === 'ResetScalesErrors') {
                let obj = { ...state.AppError }

                obj["SameScalesData"] = {
                    count: 0,
                    message: null
                }
                obj["SameScalesParam"] = {
                    count: 0,
                    message: null
                }
                return {
                    ...state,
                    AppError: obj
                }
            } else {
                let obj = { ...state.AppError }

                obj[action.name].count = (obj[action.name].count || 0) + 1

                if (action.message) {
                    obj[action.name].message = action.message
                }
                return {
                    ...state,
                    AppError: obj
                }
            }
        default:
            return state
    }
}

export const SetErrorCount = (name, count, message) => ({type: SET_ERROR_COUNT, name, count, message})

export default AppErrorReducer
