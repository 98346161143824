import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST,
    SET_CARRIERS_RATES_LIST,
    SET_ORDER_DETAILS_EDIT_SAVE_BUTTON,
    SET_RATES_LIST,
    SET_UPLOADING_FILES_LIST,
    SET_USER_LIST,
    SET_COMPANY_ADDRESS_LIST, SET_COMPANY_SHOP_ADDRESS_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"
import {setContactUsValidationErrors, setInputErrorsList} from "../commonReducer/commonReducer"

const initialState = {
    CompanyAddressList: [],
    CompanyShopAddressList: []
}

const CompanyAddressReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY_ADDRESS_LIST:
            return {
                ...state,
                CompanyAddressList: action.list
            }
        case SET_COMPANY_SHOP_ADDRESS_LIST:
            return {
                ...state,
                CompanyShopAddressList: action.list
            }
        default:
            return state
    }
}

export const setCompanyAddressList = (list) => ({type: SET_COMPANY_ADDRESS_LIST, list})
export const setCompanyShopAddressList = (list) => ({type: SET_COMPANY_SHOP_ADDRESS_LIST, list})

export const getCompanyShopAddressList = (address_id) => (dispatch, getState) => {
    SettingsApi.getCompanyShopAddressList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setCompanyShopAddressList(response.data.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
            if (error.response?.data?.validation_errors) {
                // Request made and server responded
                dispatch(setInputErrorsList(error.response.data.validation_errors))
            }
        }
    })
}

export const createCompanyShopAddress = (address_id, shops_list) => (dispatch, getState) => {
    SettingsApi.createCompanyShopAddress(getState().auth.AuthToken, address_id, shops_list).then(response => {
        CheckAndNotify(response)
        dispatch(getCompanyShopAddressList())
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
            if (error.response?.data?.validation_errors) {
                // Request made and server responded
                dispatch(setInputErrorsList(error.response.data.validation_errors))
            }
        }
    })
}
export const removeCompanyShopAddress = (address_id, shops_list) => (dispatch, getState) => {
    let AddressShops = getState().companyAddressReducer.CompanyShopAddressList.filter(c => c.address_id === address_id).map(a => { return { shop_id: a.shop_id } })
    if (AddressShops.length > 0) {
        SettingsApi.removeCompanyShopAddress(getState().auth.AuthToken, AddressShops).then(response => {
            CheckAndNotify(response)
            dispatch(getCompanyShopAddressList())
            if (shops_list.length > 0) {
                dispatch(createCompanyShopAddress(address_id, shops_list))
            }
        }).catch(error => {
            dispatch(getCompanyShopAddressList())
            if (shops_list.length > 0) {
                dispatch(createCompanyShopAddress(address_id, shops_list))
            }
            if (error) {
                dispatch(LogOutOnAuthError(error))
                CheckAndNotifyErrors(error)
                if (error.response?.data?.validation_errors) {
                    // Request made and server responded
                    dispatch(setInputErrorsList(error.response.data.validation_errors))
                }
            }
        })
    } else {
        if (shops_list.length > 0) {
            dispatch(createCompanyShopAddress(address_id, shops_list))
        }
    }
}

export const createCompanyAddress = (company_name, company_address1, company_address2, company_address3, company_city, company_state, company_country, company_postal_code, company_phone, ClearAndClose, shops_list) => (dispatch, getState) => {
    SettingsApi.CreateCompanyAddress(getState().auth.AuthToken, company_name, company_address1, company_address2, company_address3, company_city, company_state, company_country, company_postal_code, company_phone).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Succes', 'success')
        dispatch(setInputErrorsList([]))
        dispatch(getCompanyAddressList())
        dispatch(createCompanyShopAddress(response.data.data.address_id, shops_list.map(shop => { return { "shop_id": shop.value } })))
        ClearAndClose()
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
            if (error.response?.data?.validation_errors) {
                // Request made and server responded
                dispatch(setInputErrorsList(error.response.data.validation_errors))
            }
        }
    })
}

export const editCompanyAddress  = (address_id, company_name, company_address1, company_address2, company_address3, company_city, company_state, company_country, company_postal_code, company_phone, ClearAndClose, shops_list) => (dispatch, getState) => {
    SettingsApi.EditCompanyAddress(getState().auth.AuthToken, address_id, company_name, company_address1, company_address2, company_address3, company_city, company_state, company_country, company_postal_code, company_phone).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Succes', 'success')
        dispatch(setInputErrorsList([]))
        dispatch(getCompanyAddressList())
        dispatch(removeCompanyShopAddress(address_id, shops_list.map(shop => { return { "shop_id": shop.value } })))
        ClearAndClose()
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
            if (error.response?.data?.validation_errors) {
                // Request made and server responded
                dispatch(setInputErrorsList(error.response.data.validation_errors))
            }
        }
    })
}

export const getCompanyAddressList = () => (dispatch, getState) => {
    SettingsApi.getCompanyAddressList(getState().auth.AuthToken).then(response => {
        dispatch(setCompanyAddressList(response.data.data.data))
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const deleteCompanyAddress = (address_id) => (dispatch, getState) => {
    SettingsApi.deleteCompanyAddress(getState().auth.AuthToken, address_id).then(response => {
        dispatch(getCompanyAddressList())
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}


export default CompanyAddressReducer
