import ShippingApi from "../../api/OrdersApi/ShippingApi"
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {CheckAndNotify} from "./ordersReducer"

const SHIPPING_LIST = 'SHIPPING/SET-SHIPPING-LIST'
const CLEAR_SHIPPING_LIST = 'SHIPPING/CLEAR_SHIPPING_LIST'
const SET_FLOATING_VALUE = 'SHIPPING/SET_FLOATING_VALUE'
const SET_DETAILS_VIEW_INFO = 'SHIPPING/SET_DETAILS_VIEW_INFO'
const REMOVE_DETAILS_VIEW_INFO = 'SHIPPING/REMOVE_DETAILS_VIEW_INFO'
const SET_DETAILS_INFO_SELECTED = 'SHIPPING/SET_DETAILS_INFO_SELECTED'
const ADD_SELECTED_ROW = 'SHIPPING/ADD_SELECTED_ROW'
const REMOVE_SELECTED_ROW = 'SHIPPING/REMOVE_SELECTED_ROW'
const CLEAR_SELECTED_ROWS = 'SHIPPING/CLEAR_SELECTED_ROWS'
const SET_SHIPPING_CONDITIONS = 'SHIPPING/SET_SHIPPING_CONDITIONS'
const CLEAR_SHIPPING_CONDITIONS = 'SHIPPING/CLEAR_SHIPPING_CONDITIONS'
const SET_SHIPPING_SORT_CONDITIONS = 'SHIPPING/SHIPPING_SORT_CONDITIONS'
const UPDATE_SHIPPING_CONDITIONS = 'SHIPPING/UPDATE_SHIPPING_CONDITIONS'
const SET_STATUS_FLOATING_FILTER = 'SHIPPING/SET_STATUS_FLOATING_FILTER'
const SET_DETAILS_VIEW_FULL_SCREEN = 'SHIPPING/SET_DETAILS_VIEW_FULL_SCREEN'
const SET_COUNTRIES = 'SHIPPING/SET_COUNTRIES'
const SET_SHIPPING_DECLARATION_ERROR_LIST = 'SHIPPING/SET_SHIPPING_DECLARATION_ERROR_LIST'
const REMOVE_SHIPPING_DECLARATION_ERROR = 'SHIPPING/REMOVE_SHIPPING_DECLARATION_ERROR'
const SET_CUSTOM_DECLARATION_ACTIVE = 'SHIPPING/SET_CUSTOM_DECLARATION_ACTIVE'
const SET_SHIPPING_DETAILS_ERROR_LIST = 'SHIPPING/SET_SHIPPING_DETAILS_ERROR_LIST'
const REMOVE_SHIPPING_DETAILS_ERROR = 'SHIPPING/REMOVE_SHIPPING_DETAILS_ERROR'
const SET_SHIPPING_DETAILS_ACTIVE = 'SHIPPING/SET_SHIPPING_DETAILS_ACTIVE'
const SET_SHIPPING_DETAILS_EDIT_SAVE_BUTTON = 'SHIPPING/SET_SHIPPING_DETAILS_EDIT_SAVE_BUTTON'
const REMOVE_SHIPPING_DETAILS_EDIT_VALIDATION_ERRORS = 'SHIPPING/REMOVE_SHIPPING_DETAILS_EDIT_VALIDATION_ERRORS'
const SET_MANIFEST = 'SHIPPING/SET_MANIFEST'

const initialState = {
    ShippingList: [],
    ShippingConditions: {},
    ShippingSortConditions: { sortingColumn: null, isInverted: null },
    currentPage: 1,
    PageSize: 50,
    ShippingListMessage: null,
    ShippingFloatingInputValues: {},
    DetailsView: {},
    DetailsViewSelected: false,
    SelectedRows: [],
    ShippingStatusFloatingFilter: '',
    Countries: null,
    ShippingDeclarationErrorList: null,
    isCustomDeclarationActive: false,
    isShippingDetailsActive: false,
    isActive_DetailsEdit_SaveButton: true
}

const shippingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHIPPING_LIST:
            return {
                ...state,
                ShippingList: /*[...state.ShippingList, ...action.shipping_list].filter((v, i, a) => a.findIndex(t => (t.order_id === v.order_id)) === i)*/ [...state.ShippingList, ...action.shipping_list]
            }
        case CLEAR_SHIPPING_LIST:
            return {
                ...state,
                ShippingList: []
            }
        case SET_FLOATING_VALUE:
            return {
                ...state,
                ShippingFloatingInputValues: {...state.ShippingFloatingInputValues, [action.key]: action.value}
            }
        case SET_DETAILS_VIEW_INFO:
            return {
                ...state,
                DetailsView : {
                    ...state.DetailsView,
                    selected: action.selected,
                    data: action.data
                }
            }
        case SET_DETAILS_VIEW_FULL_SCREEN:
            return {
                ...state,
                DetailsView : {
                    ...state.DetailsView, isFullScreen: action.isFullScreen
                }
            }
        case REMOVE_DETAILS_VIEW_INFO:
            return {
                ...state,
                DetailsView : null
            }
        case SET_DETAILS_INFO_SELECTED:
            return {
                ...state,
                DetailsViewSelected : action.selected
            }
        case ADD_SELECTED_ROW:
            return {
                ...state,
                SelectedRows : [...state.SelectedRows, {id: action.id, data: action.data}]
            }
        case REMOVE_SELECTED_ROW:
            return {
                ...state,
                SelectedRows : state.SelectedRows.filter(row => row.id !== action.id)
            }
        case CLEAR_SELECTED_ROWS:
            return {
                ...state,
                SelectedRows : [],
                DetailsViewSelected: false
            }
        case SET_SHIPPING_CONDITIONS:
            return {
                ...state,
                ShippingConditions: {...state.ShippingConditions, [action.condition_name]: {...state.ShippingConditions[action.condition_name], "filter": action.condition_value}},
                currentPage: 1,
                ShippingList: []
            }
        case CLEAR_SHIPPING_CONDITIONS:
            return {
                ...state,
                ShippingConditions: {},
                currentPage: 1
            }
        case SET_SHIPPING_SORT_CONDITIONS:
            return {
                ...state,
                ShippingSortConditions: {sortingColumn: action.sortingColumn, isInverted: action.isInverted},
                currentPage: 1
            }
        case UPDATE_SHIPPING_CONDITIONS:
            return {
                ...state,
                ShippingConditions: {...state.ShippingConditions, [action.sortingColumn]: {...state.ShippingConditions[action.sortingColumn], "order_by": action.sort_by}},
                currentPage: 1,
                ShippingList: []
            }
        case SET_STATUS_FLOATING_FILTER:
            return {
                ...state,
                ShippingStatusFloatingFilter: action.status
            }
        case SET_COUNTRIES:
            return {
                ...state,
                Countries: action.countries
            }
        case SET_SHIPPING_DECLARATION_ERROR_LIST:
            return {
                ...state,
                ShippingDeclarationErrorList: action.list
            }
        case REMOVE_SHIPPING_DECLARATION_ERROR:
            return {
                ...state,
                ShippingDeclarationErrorList: {...state.ShippingDeclarationErrorList, [action.key]: null}
            }
        case SET_CUSTOM_DECLARATION_ACTIVE:
            return {
                ...state,
                isCustomDeclarationActive: action.active
            }
        case SET_SHIPPING_DETAILS_ERROR_LIST:
            return {
                ...state,
                OrderDetails_ErrorList: action.list
            }
        case REMOVE_SHIPPING_DETAILS_ERROR:
            return {
                ...state,
                OrderDetails_ErrorList: {...state.OrderDetails_ErrorList, [action.key]: null}
            }
        case SET_SHIPPING_DETAILS_ACTIVE:
            return {
                ...state,
                isShippingDetailsActive: action.active
            }
        case SET_SHIPPING_DETAILS_EDIT_SAVE_BUTTON:
            return {
                ...state,
                isActive_DetailsEdit_SaveButton: action.active
            }
        case REMOVE_SHIPPING_DETAILS_EDIT_VALIDATION_ERRORS:
            return {
                ...state,
                OrderDetails_ErrorList: null
            }
        case SET_MANIFEST:
            return  {
                ...state,
                ManifestData: action.payload
            }
        default:
            return state
    }
}
const setShippingList = (shipping_list) => ({type: SHIPPING_LIST, shipping_list})
export const clearShippingList = () => ({type: CLEAR_SHIPPING_LIST})
export const setShippingFloatingInputValues = (key, value) => ({type: SET_FLOATING_VALUE, key, value})
export const setDetailsViewInfo = (data, selected) => ({type: SET_DETAILS_VIEW_INFO, selected, data})
export const setDetailsViewFullScreen = (isFullScreen) => ({type: SET_DETAILS_VIEW_FULL_SCREEN, isFullScreen})
export const removeDetailsViewInfo = () => ({type: REMOVE_DETAILS_VIEW_INFO})
export const setDetailsViewSelected = (selected) => ({type: SET_DETAILS_INFO_SELECTED, selected})
export const addSelectedRow = (id, data) => ({type: ADD_SELECTED_ROW, id, data})
export const removeSelectedRow = (id) => ({type: REMOVE_SELECTED_ROW, id})
export const clearSelectedRow = () => ({type: CLEAR_SELECTED_ROWS})
export const setShippingConditions = (condition_name, condition_value) => ({type: SET_SHIPPING_CONDITIONS, condition_name, condition_value})
export const clearShippingConditions = () => ({type: CLEAR_SHIPPING_CONDITIONS})
export const setShippingSortConditions = (sortingColumn, isInverted) => ({
    // set actual shipping sort conditions
    type: SET_SHIPPING_SORT_CONDITIONS, sortingColumn, isInverted
})
export const updateShippingConditions = (sortingColumn, isInverted) => ({
    // after each sorting click we set our sort conditions into ShippingConditions object which should be send in API
    type: UPDATE_SHIPPING_CONDITIONS, sortingColumn, sort_by: isInverted ? "DESC" : (isInverted === false ? "ASC" : null)
})
export const setStatusFloatingFilter = (status) => ({type: SET_STATUS_FLOATING_FILTER, status})
export const setCountries = (countries) => ({type: SET_COUNTRIES, countries})
export const setCreateDeclarationValidationList = (list) => ({type: SET_SHIPPING_DECLARATION_ERROR_LIST, list})
export const removeCreateDeclarationValidationItem = (key) => ({type: REMOVE_SHIPPING_DECLARATION_ERROR, key})
export const setCostomDeclarationActive = (active) => ({type: SET_CUSTOM_DECLARATION_ACTIVE, active})
export const setDetailsEdit_validation_errors = (list) => ({type: SET_SHIPPING_DETAILS_ERROR_LIST, list})
export const removeDetailsEdit_validation_error = (key) => ({type: REMOVE_SHIPPING_DETAILS_ERROR, key})
export const setShippingDetailsEditActive = (active) => ({type: SET_SHIPPING_DETAILS_ACTIVE, active})
export const setShippingDetailsEditSaveButtonActive = (active) => ({type: SET_SHIPPING_DETAILS_EDIT_SAVE_BUTTON, active})
export const removeDetailsEdit_all_validation_errors = () => ({type: REMOVE_SHIPPING_DETAILS_EDIT_VALIDATION_ERRORS})

////////////////

export const updateAndSetSortShippingConditions = (sortingColumn, isInverted) => (dispatch) => {
    dispatch(setShippingSortConditions(sortingColumn, isInverted))
    dispatch(updateShippingConditions(sortingColumn, isInverted))
}

export const getShippingList = (limit, offset) => (dispatch, getState) => {
    let myState = getState()
    ShippingApi.postList(myState.auth.AuthToken,
        getState().orders.CurrentShop?.shop_id,
        getState().shipping.PageSize,
        getState().shipping.ShippingList.length,
        myState.shipping.ShippingConditions).then(response => {
        CheckAndNotify(response)
        dispatch(setShippingList(response.data.data.data))
        if (getState().shipping.ShippingSortConditions.isInverted === true) {
            dispatch(setShippingSortConditions(getState().shipping.ShippingSortConditions.sortingColumn, null))
        }
    }).catch(error => {
        if (error.response, 'getShippingList') {
            // Request made and server responded
            if (getState().shipping.ShippingSortConditions.isInverted === true) {
                dispatch(setShippingSortConditions(getState().shipping.ShippingSortConditions.sortingColumn, null))
            }
        } else if (error.request) {
            // The request was made but no response was received
            if (getState().shipping.ShippingSortConditions.isInverted === true) {
                dispatch(setShippingSortConditions(getState().shipping.ShippingSortConditions.sortingColumn, null))
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            if (getState().shipping.ShippingSortConditions.isInverted === true) {
                dispatch(setShippingSortConditions(getState().shipping.ShippingSortConditions.sortingColumn, null))
            }
        }
    })
}

export const getShippingDetails = (order_id, selected) => (dispatch, getState) => {
    ShippingApi.getShippingDetails(getState().auth.AuthToken, getState().orders.CurrentShop?.shop_id, order_id).then(response => {
        CheckAndNotify(response)
        dispatch(setDetailsViewInfo(response.data.data, selected))
    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            Notify('', 'Error', 'error')
        } else if (error.request) {
            // The request was made but no response was received
            Notify('', 'Error', 'error')
        } else {
            // Something happened in setting up the request that triggered an Error
            Notify('', 'Error', 'error')
        }
    })
}

export const postShippingDetailsEdit = (order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, shipping_price, tax_amount) => (dispatch, getState) => {
    ShippingApi.postShippingDetailsEdit(getState().auth.AuthToken, order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, shipping_price, tax_amount).then(response => {
        CheckAndNotify(response)
        dispatch(getOrderDetails(getState().shipping.DetailsView.data.order.order_id, true))
        dispatch(setShippingDetailsEditActive(false))
        dispatch(setShippingDetailsEditSaveButtonActive(true))
        dispatch(removeDetailsEdit_all_validation_errors())
        Notify('Edit has been changed', 'Succes', 'succes')
    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            dispatch(setShippingDetailsEditSaveButtonActive(true))
            if (error.response.data?.validation_errors) { dispatch(setDetailsEdit_validation_errors(error.response.data.validation_errors)) }
        } else if (error.request) {
            // The request was made but no response was received
            dispatch(setShippingDetailsEditSaveButtonActive(true))
            if (error.response.data?.validation_errors) { dispatch(setDetailsEdit_validation_errors(error.response.data.validation_errors)) }
        } else {
            // Something happened in setting up the request that triggered an Error
            dispatch(setShippingDetailsEditSaveButtonActive(true))
            if (error.response.data?.validation_errors) { dispatch(setDetailsEdit_validation_errors(error.response.data.validation_errors)) }
        }
    })
}

export const postShippingDetailsCreateDeclaration = (title, packaged_quantity, country_id, item_value, hs_code) => (dispatch, getState) => {
    ShippingApi.postShippingCreateDeclaration(getState().auth.AuthToken,
        getState().shipping.DetailsView.data.order.order_id,
        getState().shipping.DetailsView.data.order_products[0].asin,
        title, packaged_quantity, country_id, item_value, hs_code).then(response => {
        CheckAndNotify(response)
        dispatch(setCostomDeclarationActive(false))
        Notify('Custom Declaration has been created', 'Succes', 'succes')
    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            if (error.response.data?.validation_errors) { dispatch(setCreateDeclarationValidationList(error.response.data.validation_errors)) }
        } else if (error.request) {
            // The request was made but no response was received
            if (error.response.data?.validation_errors) { dispatch(setCreateDeclarationValidationList(error.response.data.validation_errors)) }
        } else {
            // Something happened in setting up the request that triggered an Error
            if (error.response.data?.validation_errors) { dispatch(setCreateDeclarationValidationList(error.response.data.validation_errors)) }
        }
    })
}

export const getCountries = () => (dispatch, getState) => {
    commonApi.getCountries(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setCountries(response.data.data))
    }).catch(error => {

    })

}

export default shippingReducer
