import Api from '../Api'
import axios from "axios"

class TagApi extends Api {
    getTags(token, page_id, order_id) {
        return this.http.post('manual/order-tag/list',
            {
                conditions: {}
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default new TagApi()
