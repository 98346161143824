import Api from './Api'

class problemsApi extends Api {

    getProblemsTypes(token) {
        return this.http.get('problem/list', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }

    createProblemsType(token, name) {
        return this.http.post('problem/create',
            {
                "name": name
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    updateProblemsType(token, name, problem_id) {
        return this.http.post('problem/update',
            {
                "name": name,
                "problem_id": problem_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    deleteProblemsType(token, problem_id) {
        return this.http.delete('problem/delete',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                },
                data: {
                    "problem_id": problem_id
                }
            }
        )
    }

}
export default new problemsApi()