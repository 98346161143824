import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_SHIPPING_RULES_LIST,
    SET_SHIPPING_RULES_DETAILS,
    SET_ADD_RULE_ERRORS_LIST,
    REMOVE_ORDER_DECLARATION_ERROR,
    REMOVE_ADD_RULE_ERRORS_LIST,
    SET_CALCULATOR_ERROR,
    PUSH_CALCULATOR_ERROR,
    PUSH_SHIPPING_RULE_DETAIL, CLEAR_SHIPPING_RULE_DETAIL_ARRAY, PUSH_SHIPPING_RULE_DETAIL_ARRAY, PUSH_ADD_RULE_ERROR
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"

const initialState = {
    ShippingRulesList: [],
    ShippingRulesDetails: null,
    AddRule_ValidationErrorsList: [],
    ShippingRulesDetailsArray: []


}

const shippingRulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHIPPING_RULES_LIST:
            return {
                ...state,
                ShippingRulesList: action.array.sort((a, b) => a.rule_name.localeCompare(b.rule_name))
            }
        case SET_SHIPPING_RULES_DETAILS:
            return {
                ...state,
                ShippingRulesDetails: action.object
            }
        case SET_ADD_RULE_ERRORS_LIST:
            return {
                ...state,
                AddRule_ValidationErrorsList: action.array
            }
        case REMOVE_ADD_RULE_ERRORS_LIST:
            return {
                ...state,
                AddRule_ValidationErrorsList: {...state.AddRule_ValidationErrorsList, [action.error_name]: null}
            }
        case PUSH_ADD_RULE_ERROR:
            return {
                ...state,
                AddRule_ValidationErrorsList: {...state.AddRule_ValidationErrorsList, [action.error_name]: [action.error_value]}
            }
        case PUSH_SHIPPING_RULE_DETAIL_ARRAY:
            return {
                ...state,
                ShippingRulesDetailsArray: action.conditionsArray
            }
        case CLEAR_SHIPPING_RULE_DETAIL_ARRAY:
            return {
                ...state,
                ShippingRulesDetailsArray: []
            }
        default:
            return state
    }
}

export const setShippingRulesList = (array) => ({type: SET_SHIPPING_RULES_LIST, array})
export const setShippingRuleDetails = (object) => ({type: SET_SHIPPING_RULES_DETAILS, object})
export const setAddRule_ValidationErrorsList = (array) => ({type: SET_ADD_RULE_ERRORS_LIST, array})
export const removeAddRule_ValidationErrorsList = (error_name) => ({type: REMOVE_ADD_RULE_ERRORS_LIST, error_name})
export const pushAddRule_ValidationError = (error_name, error_value) => ({type: PUSH_ADD_RULE_ERROR, error_value, error_name})
export const pushShippingRuleDetailsArray = (conditionsArray) => ({type: PUSH_SHIPPING_RULE_DETAIL_ARRAY, conditionsArray})
export const clearShippingRuleDetailsArray = () => ({type: CLEAR_SHIPPING_RULE_DETAIL_ARRAY})

export const getShippingRulesList = (conditions, RequestConditionsList, country_id, shop_id) => (dispatch, getState) => {
    SettingsApi.shippingRulesList(getState().auth.AuthToken, conditions).then(response => {
        CheckAndNotify(response)
        dispatch(setShippingRulesList(response.data.data.data))
        if (RequestConditionsList) {
            let rules_list = getState().shippingRules.ShippingRulesList
            /*rules_list.filter(r => (r.country_id === "all" && r.shop_id === "all") ||
                (r.shop_id === "all" && r.country_id === country_id) ||
                (r.country_id === "all" && r.shop_id === shop_id) ||
                (r.country_id === country_id && r.shop_id === shop_id))*/
            rules_list.sort(function compare(a, b) {
                if ((a.country_id === "all" && a.shop_id !== "all")) {
                    return -1
                } else {
                    return 1
                }
            })
            rules_list.sort(function compare(a, b) {
                if ((a.country_id !== "all" && a.shop_id === "all")) {
                    return -1
                } else {
                    return 1
                }
            })
            rules_list.sort(function compare(a, b) {
                if ((a.country_id !== "all" && a.shop_id !== "all")) {
                    return -1
                } else {
                    return 1
                }
            })
            rules_list = rules_list.map(r => (r.id))
            if (rules_list) {
                dispatch(getShippingRulesDetailsArray(rules_list))
            }
        }

    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getShippingRulesDetails = (shipping_rule_id) => (dispatch, getState) => {
    SettingsApi.shippingRulesDetails(getState().auth.AuthToken, shipping_rule_id).then(response => {
        CheckAndNotify(response)
        dispatch(setShippingRuleDetails(response.data.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getShippingRuleById = (shipping_rule_id) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        SettingsApi.shippingRulesDetails(getState().auth.AuthToken, shipping_rule_id).then(response => {
            CheckAndNotify(response)
            resolve(response)

        }).catch(error => {
            if (error) {
                dispatch(LogOutOnAuthError(error))
                CheckAndNotifyErrors(error)
            }
            reject(error)
        })
    })
}

export const getShippingRulesDetailsArray = (shipping_rule_ids_array) =>  (dispatch, getState) => {
    dispatch(clearShippingRuleDetailsArray())
    let allRequestsArray = []
    let Shipping_rules_array = []
    shipping_rule_ids_array.map(id => {
        allRequestsArray.push(SettingsApi.shippingRulesDetails(getState().auth.AuthToken, id))
    })
    Promise.all(allRequestsArray).then((resonses) => {
        resonses.map(r => {
            Shipping_rules_array.push(r.data.data.data)
        })
        dispatch(pushShippingRuleDetailsArray(Shipping_rules_array))
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRule_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}


export const deleteShippingRules = (shipping_rule_ids) => (dispatch, getState) => {
    SettingsApi.shippingRulesDelete(getState().auth.AuthToken, shipping_rule_ids).then(response => {
        CheckAndNotify(response)
        dispatch(getShippingRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const createShippingRule = (rule_name, shop_id, country_id, status, shipping_rule_conditions) => (dispatch, getState) => {
    setAddRule_ValidationErrorsList([])
    SettingsApi.shippingRulesCreate(getState().auth.AuthToken, rule_name, shop_id, country_id, status, shipping_rule_conditions).then(response => {
        CheckAndNotify(response)
        dispatch(getShippingRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRule_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const editShippingRule = (rule_id, rule_name, shop_id, country_id, status, shipping_rule_conditions) => (dispatch, getState) => {
    setAddRule_ValidationErrorsList([])
    SettingsApi.shippingRulesEdit(getState().auth.AuthToken, rule_id, rule_name, shop_id, country_id, status, shipping_rule_conditions).then(response => {
        CheckAndNotify(response)
        dispatch(getShippingRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRule_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const changeStatusShippingRule = (status, shipping_rule_ids) => (dispatch, getState) => {
    SettingsApi.shippingRulesChangeStatus(getState().auth.AuthToken, status, shipping_rule_ids).then(response => {
        CheckAndNotify(response)
        dispatch(getShippingRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default shippingRulesReducer
