import UpcApi from "../../api/UpcApi"
import OrdersApi from "../../api/OrdersApi/OrdersApi"
import { useHistory } from "react-router-dom"
import {
  SET_UPC_DATA,
  SET_UPC,
  LABELS_DATA_LIST_DETAILS,
  SET_PRODUCT_QUANTITY,
  REMOVE_PRODUCT_QUANTITY,
  SET_ORDER_DETAILS_DATA,
  SET_UPC_PROBLEM_RESPONSE,
  SET_SCALES_DATA,
  SET_SCALES_DATA_FROM_LOCALHOST,
  SET_SCALES_DATA_FROM_LOCALHOST_RESPONSE_RECEIVED,
  SET_RECENT_ACTUAL_SCALES_DATA,
  SET_PREVIOUS_ORDER_TO_LOG,
  SET_LAST_BARCODE_LABEL_ID,
  SCALES_DATA_MATCH_PREVIOUS_DATA,
  SET_RETURN_DATA
} from "../../constants/constants"
import Notify from "../../../views/components/notify/Notify"
import {
  CheckAndNotify,
  CheckAndNotifyErrors,
  getOrderItemsProblems
} from "../orders/ordersReducer"
import { isPermitted } from "../../scripts/isPermitted"
import React from "react"
import { SetErrorCount } from "../settings/AppErrorReducer"
import createProductQuantity from '../utils/createProductQuantity'

const initialState = {
  BaseUrl: process.env.REACT_APP_API,
  UpcData: {},
  UpcNumber: null,
  OrderDetails: null,
  upcProductsQuantity: [],
  ScalesData: {},
  ScalesDataFromLocalhost: null,
  ScalesDataFromLocalhost_ResponseReceived: true,
  ActualRecentScalesData: { data: null, order_id: null },
  LastBarcodeLabelId: null,
  ScalesMatchPrevData: false,
  ReturnPageData: null,
  DisablePrintLabelButton: false
}

const upcReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPC:
      return {
        ...state,
        UpcNumber: action.upc
      }
    case SET_UPC_DATA:
      return {
        ...state,
        UpcData: action.upc_data
      }
    case SET_RETURN_DATA:
      return {
        ...state,
        ReturnPageData: action.data
      }
    case SET_ORDER_DETAILS_DATA:
      return {
        ...state,
        OrderDetails: action.order_details_data
      }
    case SET_PRODUCT_QUANTITY:
      return {
          ...state,
          upcProductsQuantity : action.data
      }
    case REMOVE_PRODUCT_QUANTITY:
      return {
          ...state,
          upcProductsQuantity : []
      }
    case SET_UPC_PROBLEM_RESPONSE:
      return {
        ...state,
        UpcProblemResponseStatus: action.status
      }
    case SET_SCALES_DATA:
      return {
        ...state,
        ScalesData: action.scales_data
      }
    case SET_SCALES_DATA_FROM_LOCALHOST:
      return {
        ...state,
        ScalesDataFromLocalhost: action.payload
      }
    case SET_RECENT_ACTUAL_SCALES_DATA:
      return {
        ...state,
        ActualRecentScalesData: {
          data: action.scales_data,
          order_id: action.order_id
        }
      }
    case SET_SCALES_DATA_FROM_LOCALHOST_RESPONSE_RECEIVED:
      return {
        ...state,
        ScalesDataFromLocalhost_ResponseReceived: action.payload
      }
    case SET_PREVIOUS_ORDER_TO_LOG:
      return {
        ...state,
        PreviousOrderToLog: {
          order_log_type_id: action.order_log_type_id,
          order_id: action.order_id
        }
      }
    case SET_LAST_BARCODE_LABEL_ID:
      return {
        ...state,
        LastBarcodeLabelId: action.payload
      }
    case SCALES_DATA_MATCH_PREVIOUS_DATA:
      return {
        ...state,
        ScalesMatchPrevData: action.boolean
      }
    default:
      return state
  }
}

export const setUpcNumber = (upc) => ({ type: SET_UPC, upc })
export const setUpcData = (upc_data) => ({ type: SET_UPC_DATA, upc_data })
export const setRetunPageData = (data) => ({ type: SET_RETURN_DATA, data })
export const setUpcProductQuantity = (data) => ({type: SET_PRODUCT_QUANTITY, data})
export const removeUpcProductQuantity = () => ({type: REMOVE_PRODUCT_QUANTITY})
export const setOrderDetails = (order_details_data) => ({
  type: SET_ORDER_DETAILS_DATA,
  order_details_data
})
export const setUpcProblemResponse = (status) => ({
  type: SET_UPC_PROBLEM_RESPONSE,
  status
})
export const setScalesFromLocalhost = (payload) => ({
  type: SET_SCALES_DATA_FROM_LOCALHOST,
  payload
})
export const setScalesFromLocalhost_ResponseReceived = (payload) => ({
  type: SET_SCALES_DATA_FROM_LOCALHOST_RESPONSE_RECEIVED,
  payload
})
export const setPreviousOrderToLog = (order_log_type_id, order_id) => ({
  type: SET_PREVIOUS_ORDER_TO_LOG,
  order_log_type_id,
  order_id
})

export const setLastBarcodeLabelId = (payload) => ({
  type: SET_LAST_BARCODE_LABEL_ID,
  payload
})

export const getUpcData = (Upc, errorCallback, setLoading) => (dispatch, getState) => {
  setLoading && setLoading(true)

  UpcApi.getDataByUpc(getState().auth.AuthToken, Upc)
    .then((response) => {
      setLoading && setLoading(false)
      CheckAndNotify(response)
      dispatch(setUpcData(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
        setLoading && setLoading(false)
        errorCallback && errorCallback()
      }
    })
}

export const getReturnPageData = (Upc) => (dispatch, getState) => {
  UpcApi.getReturnPageData(getState().auth.AuthToken, Upc)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(setRetunPageData(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const setScalesData = (scales_data) => ({
  type: SET_SCALES_DATA,
  scales_data
})
export const setRecentActualScalesData = (scales_data, order_id) => ({
  type: SET_RECENT_ACTUAL_SCALES_DATA,
  scales_data,
  order_id
})
export const setScalesDataMatchPerviousData = (boolean) => ({
  type: SCALES_DATA_MATCH_PREVIOUS_DATA,
  boolean
})

export const getScalesData = (orderItemId) => (dispatch, getState) => {
  UpcApi.getDataFromScales(getState().auth.AuthToken, orderItemId)
    .then((response) => {
      CheckAndNotify(response)
      dispatch(setScalesData(response.data.data))
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const getOrderDetails = (shop_id, order_id) => (dispatch, getState) => {
  OrdersApi.getOrderDetails(getState().auth.AuthToken, shop_id, order_id)
    .then((response) => {
      CheckAndNotify(response)
      const orderDitails = createProductQuantity(response.data.data)
      dispatch(setUpcProductQuantity(orderDitails))
      dispatch(setOrderDetails(response.data.data))
    })
    .catch((error) => {
      if (error) {
        CheckAndNotifyErrors(error)
        dispatch(LogOutOnAuthError(error))
      }
    })
}

export const LogOutOnAuthError = (error) => (dispatch, getState) => {
  error?.response?.data?.messages?.map((m) => {
    if (m === "Authorization error") {
      window.localStorage.clear()
      window.localStorage.setItem("isAuthError", true)
      window.location.pathname = "/login"
      Notify(m, "Error", "error")
    }
  })
}

export const sendUpcProblemF =
  (
    order_item_id,
    order_item_status_id,
    setIsFetching,
    problem_id,
    problem_description,
    Callback,
    order_id,
    page_id
  ) =>
  (dispatch, getState) => {
    setIsFetching(true)
    UpcApi.sendUpcProblemApi(
      getState().auth.AuthToken,
      order_item_id,
      order_item_status_id,
      problem_id,
      problem_description,
      page_id
    )
      .then((response) => {
        CheckAndNotify(response)
        dispatch(setUpcProblemResponse(response.data.status))
      })
      .catch((error) => {
        if (error) {
          dispatch(LogOutOnAuthError(error))
          CheckAndNotifyErrors(error)
        }
      })
      .finally(() => {
        setIsFetching(false)
        if (Callback) {
          Callback()
          dispatch(getOrderItemsProblems(order_id))
        }
      })
  }

const sendProcessingTime = (page_id, order_id) => (dispatch, getState) => {
  UpcApi.sendUpcOrderProcessingTime(
    getState().auth.AuthToken,
    page_id,
    order_id
  )
    .then((response) => {
      CheckAndNotify(response)
    })
    .catch((error) => {
      if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
      }
    })
}

export const sendUpcOrderProcessingTime =
  (order_log_type_id, order_id) => (dispatch, getState) => {
    /*let prev = JSON.parse(localStorage.getItem('PreviousOrderToLog'))
    if (prev?.order_log_type_id === 0) {
        dispatch(sendProcessingTime(1, prev.order_id))
        localStorage.setItem('PreviousOrderToLog', JSON.stringify({order_log_type_id: null, order_id: null}))
    }*/
    if (
      isPermitted(
        "/work-log",
        "order-log",
        getState().permissionsReducer.Permissions
      )
    ) {
      dispatch(sendProcessingTime(order_log_type_id, order_id))
    }
    /*
    localStorage.setItem('PreviousOrderToLog', JSON.stringify({order_log_type_id: order_log_type_id, order_id: order_id}))
*/
  }

const ScalesDataValidator = (data, current_order, previous_order, dispatch) => {
  if (data) {
    let dataArray = data?.split(";")
    if (dataArray) {
      let length = Number(dataArray[0])
      let width = Number(dataArray[1])
      let height = Number(dataArray[2])
      let weight = Number(dataArray[4])
      /*if (dataArray.length !== 20) {
                Notify('Scales data length is less or more than 20 parameters', 'Error', 'error')
                dispatch(setScalesFromLocalhost(null))
            } else */
      if (weight > 10) {
        if (current_order !== previous_order) {
          Notify(
            `Scales weight is more than 10.0, please make sure that it is proper weight`,
            "Warning",
            "warning"
          )
        }
      }
      // if (length <= 0) {
      //     dispatch(setScalesFromLocalhost(null))
      // } else if (width <= 0) {
      //     dispatch(setScalesFromLocalhost(null))
      // } else if (height <= 0) {
      //     dispatch(setScalesFromLocalhost(null))
      // } else if (weight <= 0) {
      //     dispatch(setScalesFromLocalhost(null))
      // } else {
      //
      // }
      if (0 < length && length < 1) {
        length = 1
      }
      if (0 < width && width < 1) {
        width = 1
      }
      if (0 < height && height < 1) {
        height = 1
      }
      return {
        length,
        width,
        height,
        weight
      }
    } else {
      Notify("Wrong data format", "Error", "error")
      dispatch(setScalesFromLocalhost(null))
    }
  } else {
    Notify("No Data", "Error", "error")
    dispatch(setScalesFromLocalhost(null))
  }
}

export const getScalesDataFromLocalhost =
  (order_id) => (dispatch, getState) => {
    const getScalesHandler = (options) => {
      if (options.emulate) {
        let prev_data_order_id =
          getState().upcReducer.ActualRecentScalesData.order_id
        let prev_data = getState().upcReducer.ActualRecentScalesData.data
        let ScalesData = ScalesDataValidator(
          "4.5;2;2;0.008;2;2;;;;;;;;;;;20;20;20;12.2",
          order_id,
          prev_data_order_id
        )
        if (ScalesData) {
          dispatch(setScalesFromLocalhost(ScalesData))
          if (!prev_data_order_id) {
            dispatch(setRecentActualScalesData(ScalesData, order_id))
          } else if (order_id === prev_data_order_id) {
            dispatch(setRecentActualScalesData(ScalesData, order_id))
          } else if (prev_data_order_id && order_id !== prev_data_order_id) {
            if (
              prev_data.weight === ScalesData.weight &&
              prev_data.length === ScalesData.length &&
              prev_data.width === ScalesData.width &&
              prev_data.height === ScalesData.height
            ) {
              Notify(
                `Received the same scales data as from previous order, please make sure that it is proper data`,
                "Warning",
                "warning"
              )
            }
            dispatch(setRecentActualScalesData(ScalesData, order_id))
          }
        }
        dispatch(setScalesFromLocalhost_ResponseReceived(true))
      } else {
        UpcApi.getScalesDataFromLocalhost(getState().auth.AuthToken)
          .then((response) => {
            let prev_data_order_id =
              getState().upcReducer.ActualRecentScalesData.order_id
            let prev_data = getState().upcReducer.ActualRecentScalesData.data
            let ScalesData = ScalesDataValidator(
              response.data,
              order_id,
              prev_data_order_id,
              dispatch
            )
            if (ScalesData) {
              dispatch(setScalesFromLocalhost(ScalesData))
              if (!prev_data_order_id) {
                dispatch(setRecentActualScalesData(ScalesData, order_id))
              } else if (order_id === prev_data_order_id) {
                if (
                  prev_data.weight > 0 &&
                  prev_data.weight === ScalesData.weight &&
                  prev_data.length > 0 &&
                  prev_data.length === ScalesData.length &&
                  prev_data.width > 0 &&
                  prev_data.width === ScalesData.width &&
                  prev_data.height > 0 &&
                  prev_data.height === ScalesData.height
                ) {
                  dispatch(setScalesDataMatchPerviousData(true))
                } else {
                  dispatch(setScalesDataMatchPerviousData(false))
                }
              } else if (
                prev_data_order_id &&
                order_id !== prev_data_order_id
              ) {
                if (
                  (prev_data.weight > 1 &&
                    ScalesData.weight > 1 &&
                    prev_data.weight === ScalesData.weight) ||
                  (prev_data.length > 1 &&
                    ScalesData.length > 1 &&
                    prev_data.length === ScalesData.length) ||
                  (prev_data.width > 1 &&
                    ScalesData.width > 1 &&
                    prev_data.width === ScalesData.width) ||
                  (prev_data.height > 1 &&
                    ScalesData.height > 1 &&
                    prev_data.height === ScalesData.height)
                ) {
                  Notify(
                    `Some of the scales parameters are the same as in a previous order. Please make sure that the data is accurate`,
                    "Warning",
                    "warning"
                  )
                  dispatch(
                    SetErrorCount(
                      "SameScalesParam",
                      null,
                      "Some of the scales parameters are the same as in a previous order."
                    )
                  )
                } else if (
                  prev_data.weight > 0 &&
                  prev_data.weight === ScalesData.weight &&
                  prev_data.length > 0 &&
                  prev_data.length === ScalesData.length &&
                  prev_data.width > 0 &&
                  prev_data.width === ScalesData.width &&
                  prev_data.height > 0 &&
                  prev_data.height === ScalesData.height
                ) {
                  Notify(
                    `Received the same scales data as from previous order, please make sure that it is proper data`,
                    "Warning",
                    "warning"
                  )
                  dispatch(
                    SetErrorCount(
                      "SameScalesData",
                      null,
                      "Received the same scales data as from previous order"
                    )
                  )
                } else {
                  dispatch(SetErrorCount("ResetScalesErrors", 0))
                }
                /*else if (prev_data.weight === ScalesData.weight ||
                            prev_data.length === ScalesData.length ||
                            prev_data.width === ScalesData.width ||
                            prev_data.height === ScalesData.height) {
                            Notify(`Some of the scales' parameters are the same as in a previous order. Please make sure that the data is accurate`, 'Warning', 'warning')
                            dispatch(SetErrorCount('SameScalesParam', null, 'Some of the scales parameters are the same as in a previous order.'))
                        } */
              }
              dispatch(setRecentActualScalesData(ScalesData, order_id))
            }
          })
          .catch((error) => {
            if (error) {
              dispatch(LogOutOnAuthError(error))
              CheckAndNotifyErrors(error)
            }
          })
          .finally(() => {
            dispatch(setScalesFromLocalhost_ResponseReceived(true))
          })
      }
    }

    if (getState().auth.UserInfo?.user.blocked !== 1) {
      const scales_emulation =
        window.localStorage.getItem("scales_emulation") === "enabled"
      dispatch(setScalesFromLocalhost_ResponseReceived(false))
      if (getState().auth.UserInfo?.user?.user_id === 1 && !scales_emulation) {
        getScalesHandler({ emulate: true })
      } else {
        getScalesHandler({ emulate: false })
      }
    }
  }

export default upcReducer
