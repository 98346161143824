import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST, SET_CARRIERS_RATES_ERROR_LOG_LIST,
    SET_CARRIERS_RATES_LIST,
    SET_CARRIERS_RATES_LOG_LIST,
    SET_RATES_LIST,
    SET_UPLOADING_FILES_LIST,
    SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"

const initialState = {
    CarriersRatesList: [],
    CarriersRatesErrorLogList: [],
    CarriersRatesLogList: []

}

const CarriersCoefficientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CARRIERS_RATES_LIST:
            return {
                ...state,
                CarriersRatesList: action.list

            }
        case SET_CARRIERS_RATES_ERROR_LOG_LIST:
            return {
                ...state,
                CarriersRatesErrorLogList: action.list

            }
        case SET_CARRIERS_RATES_LOG_LIST:
            return {
                ...state,
                CarriersRatesLogList: action.list
            }
        default:
            return state
    }
}

export const setCarriersRatesList = (list) => ({type: SET_CARRIERS_RATES_LIST, list})
export const setCarrierRatesErrorLog = (list) => ({type: SET_CARRIERS_RATES_ERROR_LOG_LIST, list})
export const setCarrierRatesLog = (list) => ({type: SET_CARRIERS_RATES_LOG_LIST, list})

export const getCarriersRatesList = (company_id, rate_type_id, country_id) => (dispatch, getState) => {
    SettingsApi.getCarriersRatesList(getState().auth.AuthToken, company_id, rate_type_id, country_id).then(response => {
        CheckAndNotify(response)
        dispatch(setCarriersRatesList(response.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const updateCarriersRatesList = (company_ids, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    SettingsApi.updateCarriersRatesList(getState().auth.AuthToken, company_ids).then(response => {
        CheckAndNotify(response)
        Notify(response.data?.message, 'Success', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}
export const uploadCarrierRatesError = () => (dispatch, getState) => {
    SettingsApi.uploadCarrierRatesError(getState().auth.AuthToken).then(response => {
        dispatch(setCarrierRatesErrorLog(response.data.data))
        CheckAndNotify(response)
        Notify(response.data?.message, 'Success', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}
export const uploadCarrierRatesLog = () => (dispatch, getState) => {
    SettingsApi.uploadCarrierRatesLog(getState().auth.AuthToken).then(response => {
        dispatch(setCarrierRatesLog(response.data.data))
        CheckAndNotify(response)
        Notify(response.data?.message, 'Success', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}
export const setCarrierRatesErrorIsViewed = (id) => (dispatch, getState) => {
    SettingsApi.setCarrierRatesErrorIsViewed(getState().auth.AuthToken, id).then(response => {
        dispatch(uploadCarrierRatesError())
        dispatch(setCarrierRatesLog(response.data.data))
        CheckAndNotify(response)
        Notify(response.data?.message, 'Success', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default CarriersCoefficientsReducer
