import axios from 'axios'

class Api {
    constructor() {
        this.http = this._instance()
    }

    _instance() {
        return axios.create({
            withCredentials: true,
            baseURL: this._baseUrl(),
            headers: {
                "Content-type": this._contentType()
                /*"Token": window.localStorage.getItem('AuthDeliKey')*/
            }
        })
    }
    _baseUrl() {
        return process.env.REACT_APP_API
    }

    _contentType() {
            return process.env.REACT_APP_API_CONTENT_TYPE
    }
}

export default Api

