const createProductQuantity = ({ order_products }) =>
  order_products.map(
    ({ shipped_quantity, ordered_quantity, order_item_id }) => ({
      shipped_quantity,
      custom_quantity: shipped_quantity,
      ordered_quantity,
      order_item_id
    })
  )

  export default createProductQuantity
