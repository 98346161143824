import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError 
} from "../orders/ordersReducer"
import WorkLogApi from "../../api/WorkLogApi"

export const initialState = {
    WorkLogTimeInfo: [],
    WorkLogTimeInfoResponseReceived: false,
    isWorkLogLoading: true,
    isUpdating: false,
    currentStatus: null
}

const workLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_WORK_TIME_INFO':
            return {
                ...state,
                WorkLogTimeInfo: [
                    ...action.payload.sort((a, b) => {
                        let c = new Date(a.start_time)
                        let d = new Date(b.start_time)
                        return c - d
                    })
                ]
            }
        case 'SET_WORK_LOG_LOADING':
            return {
                ...state,
                isWorkLogLoading: action.payload
            }
        case 'SET_UPDATING_LOADER':
            return {
                ...state,
                isWorkLogLoading: action.payload
            }
        case 'SET_CURRENT_STATUS':
            return {
                ...state,
                currentStatus: action.payload
            }
        default:
            return state
    }
}

// UPDATE WORK STATUS
export const setUpdatingLoading = (payload) => ({type: 'SET_UPDATING_LOADER', payload})

export const setCurrentStatus = (payload) => ({type: 'SET_CURRENT_STATUS', payload})

export const changeWorkLogStatus = (work_log_type_id, work_log_status) => async (dispatch, getState) => {
    try {
        dispatch(setUpdatingLoading(true))
        const response = await WorkLogApi.changeWorkLogStatus(getState().auth.AuthToken, work_log_type_id, work_log_status)
        CheckAndNotify(response)
        dispatch(setCurrentStatus(getState().workLogReducer.currentStatus === work_log_type_id ? null : work_log_type_id))
    } catch (error) {
        console.log(error)
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
    } finally {
        dispatch(setUpdatingLoading(false))
    }
}

export const backFromBreak = () => async (dispatch, getState) => {
    try {
        dispatch(setUpdatingLoading(true))
        await WorkLogApi.changeWorkLogStatus(getState().auth.AuthToken, 2, 0)
        await WorkLogApi.changeWorkLogStatus(getState().auth.AuthToken, 1, 0)
        dispatch(setCurrentStatus(1))
    } catch (error) {
        console.log(error)
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
    } finally {
        dispatch(setUpdatingLoading(false))
    }
}

// WORK LOG
export const setWorkTimeInfo = (payload) => ({type: 'SET_WORK_TIME_INFO', payload})

export const setWorkLogLoading = (payload) => ({type: 'SET_WORK_LOG_LOADING', payload})

export const getWorkLogTimeInfo = () => async (dispatch, getState) => {
    try {
        dispatch(setWorkLogLoading(true))
        const response = await WorkLogApi.getWorkLogTimeInfo(getState().auth.AuthToken)
        let responseData = response.data.data
        if (responseData?.length > 0) {
            let lastValue = responseData[responseData.length - 1]
            if (lastValue.work_log_type_id === 2 && lastValue.work_log_status === 0) {
                dispatch(CloseUserOrderLog())
            }
        }
        const currentSession = response.data.data.find((item) => item.work_log_status === 0)
        dispatch(setCurrentStatus(currentSession?.work_log_type_id || null))
        CheckAndNotify(response)
        dispatch(setWorkTimeInfo(responseData))
    } catch (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
    } finally {
        dispatch(setWorkLogLoading(false))
    }
}

// ORDER LOG
export const CloseUserOrderLog = () => async (dispatch, getState) => {
    try {
        await WorkLogApi.CloseUserOrderLog(getState().auth.AuthToken)
    } catch (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
    }
}

export default workLogReducer
