import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_CARRIERS_RATES_LOG_LIST,
    SET_SHOP_COEFFICIENTS_LIST
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"

// define the initial state of the reducer
const initialState = {
    ShopCoefficientsList: []
}

// define the reducer function
const ShopCoefficientsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SHOP_COEFFICIENTS_LIST:
            return {
                ...state,
                ShopCoefficientsList: action.list
            }
        default:
            return state
    }
}

export const setCarrierRatesLog = (list) => ({type: SET_SHOP_COEFFICIENTS_LIST, list})

function combineObjects(arr) {
    const result = []
    const map = new Map()

    for (const obj of arr) {
        const key = obj.company_id + '-' + obj.shop_id
        if (!map.has(key)) {
            map.set(key, { company_id: obj.company_id, shop_id: obj.shop_id, carriers: [] })
            result.push(map.get(key))
        }
        map.get(key).carriers.push({ carrier_id: obj.carrier_id, service_id: obj.service_id, coef: obj.coef })
    }

    return result
}

export const getShopCoefficientsList = () => (dispatch, getState) => {
    SettingsApi.getShopCoefficientsList(getState().auth.AuthToken).then(response => {
        let data = response.data.data
        let combined_response = combineObjects(data)
        let CompanyShopsList = getState().orders.CompanyShopsList
        const companyIdToName = (company_id) => {
            return CompanyShopsList.find(el => el.company_id === company_id).company_name
        }
        let sorted_response = combined_response.sort((a, b) => companyIdToName(a.company_id).localeCompare(companyIdToName(b.company_id)))
        dispatch(setCarrierRatesLog(response.data.data))
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const editShopCoefficient = (company_id, shops, carriers) => (dispatch, getState) => {
    SettingsApi.editShopCoefficient(getState().auth.AuthToken, company_id, shops, carriers).then(response => {
        dispatch(getShopCoefficientsList())
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}
export const createShopCoefficient = (company_id, shops, carriers) => (dispatch, getState) => {
    SettingsApi.createShopCoefficient(getState().auth.AuthToken, company_id, shops, carriers).then(response => {
        dispatch(getShopCoefficientsList())
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}
export const deleteShopCoefficient = (company_id, shops) => (dispatch, getState) => {
    SettingsApi.deleteShopCoefficient(getState().auth.AuthToken, company_id, shops).then(response => {
        dispatch(getShopCoefficientsList())
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default ShopCoefficientsReducer