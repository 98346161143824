import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST,
    SET_CARRIERS_RATES_LIST, SET_INVOICE_LINKS, SET_INVOICES_INVALID_LIST,
    SET_INVOICES_LIST, SET_LAST_CREATED_INVOICE_LINK,
    SET_RATES_LIST,
    SET_UPLOADING_FILES_LIST,
    SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"
import React from "react"

const initialState = {
    InvoicesList: [],
    InvoiceInvalidList: [],
    LastInvoiceLink: null,
    InvoiceLinks: null
}

const InvoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INVOICES_LIST:
            return {
                ...state,
                InvoicesList: action.list

            }
        case SET_INVOICES_INVALID_LIST:
            return {
                ...state,
                InvoiceInvalidList: action.list

            }
        case SET_LAST_CREATED_INVOICE_LINK:
            return {
                ...state,
                LastInvoiceLink: action.link

            }
        case SET_INVOICE_LINKS:
            return {
                ...state,
                InvoiceLinks: {
                    "invalid_invoice": action.invalid_invoice,
                    "invoice": action.invoice
                }

            }
        default:
            return state
    }
}

export const setInvoicesList = (list) => ({type: SET_INVOICES_LIST, list})
export const setInvoiceInvalidList = (list) => ({type: SET_INVOICES_INVALID_LIST, list})
export const setLastCreatedInvoiceLink = (link) => ({type: SET_LAST_CREATED_INVOICE_LINK, link})
export const setInvoiceLinks = (invalid_invoice, invoice) => ({type: SET_INVOICE_LINKS, invalid_invoice, invoice})

export const getInvoicesList = () => (dispatch, getState) => {
    SettingsApi.getInvoicesList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setInvoicesList(response.data.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getInvalidInvoiceLink = (parent_id, invoice_path_file) => (dispatch, getState) => {
    SettingsApi.getInvalidInvoiceLink(getState().auth.AuthToken, parent_id).then(response => {
        if (response.data.data.path_file) {
            dispatch(setInvoiceLinks(`${getState().orders.BaseUrl}${response.data.data.path_file}`,
                `${getState().orders.BaseUrl}${invoice_path_file}`))
        } else {
            window.open(`${getState().orders.BaseUrl}${invoice_path_file}`, '_blank')
        }
        CheckAndNotify(response)
    }).catch(error => {
        window.open(`${getState().orders.BaseUrl}${invoice_path_file}`, '_blank')
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const createInvoicePdf = (shop_ids, date_from, date_to) => (dispatch, getState) => {
    dispatch(setLastCreatedInvoiceLink(null))
    SettingsApi.postInvoicePdf(getState().auth.AuthToken, shop_ids, date_from, date_to).then(response => {
        dispatch(getInvalidInvoiceLink(response.data.data.parent_id, response.data.data.path_file))
/*
        window.open(`${getState().orders.BaseUrl}${response.data.data.path_file}`, '_blank')
*/
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

const DownloadFile = (link) => {
    const a = document.createElement('a')
    a.href = link
    a.download = ''
    a.target = "_blank"
    a.click()
}

export const createInvoiceExcel = (shop_ids, date_from, date_to) => (dispatch, getState) => {
    dispatch(setLastCreatedInvoiceLink(null))
    SettingsApi.postInvoiceExcel(getState().auth.AuthToken, shop_ids, date_from, date_to).then(response => {
        dispatch(getInvalidInvoiceLink(response.data.data.parent_id, response.data.data.path_file))
        CheckAndNotify(response)
/*
        window.open(`${getState().orders.BaseUrl}${response.data.data.path_file}`, '_blank')
*/
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getInvoiceInvalidList = (shop_ids, date_from, date_to, path_file) => (dispatch, getState) => {
    SettingsApi.getInvoiceInvalidList(getState().auth.AuthToken, shop_ids, date_from, date_to).then(response => {
        dispatch(setInvoiceInvalidList(response.data.data.data))
        if (!(response.data.data.data.length > 0)) {
            DownloadFile(`${getState().orders.BaseUrl}${path_file}`)
        }
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default InvoiceReducer
