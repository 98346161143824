import Api from './Api'

class WorkLogApi extends Api {
    changeWorkLogStatus(token, work_log_type_id, work_log_status) {
        return this.http.post('/work/log',
            {
                "work_log_type_id": work_log_type_id,
                "work_log_status": work_log_status
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    getWorkLogTimeInfo(token) {
        return this.http.get('/work/time-info', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }
    CloseUserOrderLog(token) {
        return this.http.get('/work/close_user_order_log', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }

    getCloseUserOrderLogProcessingTime(token) {
        return this.http.get('/work/close_user_order_log_processing_time', {
            headers: {
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
    }

  
}

export default new WorkLogApi()
