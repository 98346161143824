import Api from '../Api'

class AwaitingForOrdersApi extends Api {
    postList(token, limit, offset, conditions) {
        return this.http.post('order-tab/awaiting',
            {
                "limit": limit ? limit : 50,
                "offset": offset ? offset : 0,
                "conditions": conditions ? conditions : null
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    importOrders(token, file) {
        return this.http.post('upload-new-tracker-by-file', file,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getOrderDetails(token, order_id) {
        return this.http.post('order/detail/awaiting',
            {
                "order_id": order_id,
                "shop_id": 1
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default new AwaitingForOrdersApi()