import {Frontend_Permissions_Ids} from "../reducers/permissions/permissionsReducer"

export const isPermitted = (route_name, id_name, backend_ids_list) => {
    let RouteObject = Frontend_Permissions_Ids.find(o => o.route_name === route_name)
    let ids = RouteObject.ids[id_name]
    let match = false
    ids.map(f_id => {
        if (backend_ids_list.find(b_id => Number(b_id) === Number(f_id))) {
            match = true
        }
    })
    if (ids.length === 0) {
        match = true
    }
    return match
}