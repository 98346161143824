import Api from './Api'

class CalculatorApi extends Api {

    getAllCountries(token) {
       return  this.http.get('manual/countries', {
           headers: {
               "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
           }
       })
    }

    postCalculatorShippingCost(token, country, address, city, postalCode, province,
                               weightValue, weightUnit, dimensionLength, dimensionWidth, dimensionHeight, dimensionUnit) {
        return this.http.post('/delivery/cost/public',
            {
                to_address: address,
                to_city: city,
                to_country: country,
                to_postal_code: postalCode,
                to_province: province,
                package_weight_value: weightValue,
                package_weight_unit_of_measure: weightUnit,
                package_dimension_length: dimensionLength,
                package_dimension_width: dimensionWidth,
                package_dimension_height: dimensionHeight,
                package_dimension_unit_of_measure: dimensionUnit
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            })
    }

}

export default new CalculatorApi()