import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST, SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import {setUserIsBlocked} from "../auth/authReducer"

const initialState = {
    UsersList: [],
    AddUser_ValidationErrorsList: []
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADD_USER_ERRORS_LIST:
            return {
                ...state,
                AddUser_ValidationErrorsList: action.array
            }
        case REMOVE_ADD_USER_ERRORS_LIST:
            return {
                ...state,
                AddUser_ValidationErrorsList: {...state.AddUser_ValidationErrorsList, [action.error_name]: null}
            }
        case PUSH_ADD_USER_ERROR:
            return {
                ...state,
                AddUser_ValidationErrorsList: {...state.AddUser_ValidationErrorsList, [action.error_name]: [action.error_value]}
            }
        case SET_USER_LIST:
            return {
                ...state,
                UsersList: Object.keys(action.object).map((key) => action.object[key])
            }
        default:
            return state
    }
}

export const setAddUser_ValidationErrorsList = (array) => ({type: SET_ADD_USER_ERRORS_LIST, array})
export const removeAddUser_ValidationErrorsList = (error_name) => ({type: REMOVE_ADD_USER_ERRORS_LIST, error_name})
export const pushAddUser_ValidationError = (error_name, error_value) => ({type: PUSH_ADD_USER_ERROR, error_value, error_name})
export const setUsersList = (object) => ({type: SET_USER_LIST, object})

export const getUsersList = () => (dispatch, getState) => {
    PermissionsApi.getUsersList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setUsersList(response.data.data.data))
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const createUser = (email, password, password_confirmation, first_name, last_name, country, shipments_per_month, phone, company_id, invite_code, hear_about_us, role_id) => (dispatch, getState) => {
    PermissionsApi.createUser(getState().auth.AuthToken, email, password, password_confirmation, first_name, last_name, country, shipments_per_month, phone, company_id, invite_code, hear_about_us, role_id).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Success', 'success')
        dispatch(setAddUser_ValidationErrorsList([]))
        dispatch(getUsersList())
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const editUser = (user_id, email, first_name, last_name, country, shipments_per_month, phone, company_id, invite_code, hear_about_us, role_id) => (dispatch, getState) => {
    PermissionsApi.editUser(getState().auth.AuthToken, user_id, email, first_name, last_name, country, shipments_per_month, phone, company_id, invite_code, hear_about_us, role_id).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Success', 'success')
        dispatch(setAddUser_ValidationErrorsList([]))
        dispatch(getUsersList())
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const deleteUser = (user_id) => (dispatch, getState) => {
    PermissionsApi.deleteUser(getState().auth.AuthToken, user_id).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Success', 'success')
        dispatch(getUsersList())
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const isBlockedUser = (user_id, token) => (dispatch, getState) => {
    PermissionsApi.isBlockedUser(token || getState().auth.AuthToken, user_id).then(response => {
        dispatch(setUserIsBlocked(response.data.data.is_on))
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
/*            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)*/
        }
    })
}

export const blockUser = (user_id, message) => (dispatch, getState) => {
    PermissionsApi.blockUser(getState().auth.AuthToken, user_id).then(response => {
        CheckAndNotify(response)
        dispatch(isBlockedUser(getState().auth.UserInfo.user.user_id))
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const unblockUser = (user_id, password, message) => (dispatch, getState) => {
    PermissionsApi.unblockUser(getState().auth.AuthToken, user_id, password).then(response => {
        CheckAndNotify(response)
        dispatch(isBlockedUser(getState().auth.UserInfo.user.user_id))
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddUser_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}


export default usersReducer
