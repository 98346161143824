import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    ORDERS_LIST,
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST, SET_RATES_LIST, SET_UPLOADING_FILES_LIST, SET_USER_LIST

} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"
import ratesList from "../../../views/pages/settings/settings-content/commonComponents/AddRates/ratesList"
import {getShippingRulesList} from "./shippingRulesReducer"

const initialState = {

}

const uploadReturnLabelsReducer = (state = initialState, action) => {
    switch (action.type) {
        /*case SET_UPLOADING_FILES_LIST:
            return {
                ...state,
                UploadingFilesList: action.list

            }*/
        default:
            return state
    }
}

/*
export const setUploadingFilesList = (list) => ({type: SET_UPLOADING_FILES_LIST, list})
*/

export const uploadReturnLabels = (files_list) => (dispatch, getState) => {
    SettingsApi.uploadReturnLabels(getState().auth.AuthToken, files_list).then(response => {
        CheckAndNotify(response, true)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default uploadReturnLabelsReducer
