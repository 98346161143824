import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_ADD_USER_ERRORS_LIST,
    REMOVE_ADD_USER_ERRORS_LIST,
    PUSH_ADD_USER_ERROR, REMOVE_ADD_ROLE_ERRORS_LIST, SET_ADD_ROLE_ERRORS_LIST, PUSH_ADD_ROLE_ERROR, SET_ROLES_LIST
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import PermissionsApi from "../../api/SettingsApi/PermissionsApi/PermissionsApi"
import {setAddCustomsDeclarationRule_ValidationErrorsList} from "./customsDeclarationRulesReducer"

const initialState = {
    RolesList: [],
    AddRole_ValidationErrorsList: []
}

const rolesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ADD_ROLE_ERRORS_LIST:
            return {
                ...state,
                AddRole_ValidationErrorsList: action.array
            }
        case REMOVE_ADD_ROLE_ERRORS_LIST:
            return {
                ...state,
                AddRole_ValidationErrorsList: {...state.AddRole_ValidationErrorsList, [action.error_name]: null}
            }
        case PUSH_ADD_ROLE_ERROR:
            return {
                ...state,
                AddRole_ValidationErrorsList: {...state.AddRole_ValidationErrorsList, [action.error_name]: [action.error_value]}
            }
        case SET_ROLES_LIST:
            return {
                ...state,
                RolesList: action.array.reverse()
            }
        default:
            return state
    }
}

export const setAddRole_ValidationErrorsList = (array) => ({type: SET_ADD_ROLE_ERRORS_LIST, array})
export const removeAddRole_ValidationErrorsList = (error_name) => ({type: REMOVE_ADD_ROLE_ERRORS_LIST, error_name})
export const pushAddRole_ValidationError = (error_name, error_value) => ({type: PUSH_ADD_ROLE_ERROR, error_value, error_name})
export const setRolesList = (array) => ({type: SET_ROLES_LIST, array})

export const getRolesList = () => (dispatch, getState) => {
    PermissionsApi.getRolesList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setRolesList(response.data.data.data))
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRole_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const createRole = (name, permission_group_id_list) => (dispatch, getState) => {
    PermissionsApi.createRole(getState().auth.AuthToken, name, permission_group_id_list).then(response => {
        CheckAndNotify(response)
        dispatch(getRolesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRole_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const editRole = (role_id, name, permission_group_id_list) => (dispatch, getState) => {
    PermissionsApi.editRole(getState().auth.AuthToken, role_id, name, permission_group_id_list).then(response => {
        CheckAndNotify(response)
        dispatch(getRolesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRole_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const deleteRole = (role_id) => (dispatch, getState) => {
    PermissionsApi.deleteRole(getState().auth.AuthToken, role_id).then(response => {
        CheckAndNotify(response)
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddRole_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default rolesReducer
