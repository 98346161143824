import problemsApi from "../../api/problemsApi"
import { SET_PROBLEM_TYPE_LIST } from "../../constants/constants"
import { CheckAndNotify, CheckAndNotifyErrors, LogOutOnAuthError } from "../orders/ordersReducer"
import Notify from "../../../views/components/notify/Notify"

const initialState = {
    ProblemTypeList: []
}

const problemsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROBLEM_TYPE_LIST:
            return {
                ...state,
                ProblemTypeList: action.list
            }
        default:
            return state
    }
}

const setProblemsTypes = (list) => ({ type: SET_PROBLEM_TYPE_LIST, list })

const handleApiError = (dispatch, error) => {
    if (error) {
        dispatch(LogOutOnAuthError(error))
        CheckAndNotifyErrors(error)
    }
}

export const getProblemsTypes = () => (dispatch, getState) => {
    problemsApi.getProblemsTypes(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setProblemsTypes(response.data.data))
    }).catch(error => {
        handleApiError(dispatch, error)
    })
}

export const updateProblemsType = (name, problem_id) => (dispatch, getState) => {
    problemsApi.updateProblemsType(getState().auth.AuthToken, name, problem_id).then(response => {
        CheckAndNotify(response, true)
        dispatch(getProblemsTypes())
    }).catch(error => {
        handleApiError(dispatch, error)
    })
}

export const createProblemsType = (name) => (dispatch, getState) => {
    problemsApi.createProblemsType(getState().auth.AuthToken, name).then(response => {
        CheckAndNotify(response, true)
        dispatch(getProblemsTypes())

    }).catch(error => {
        handleApiError(dispatch, error)
    })
}

export const deleteProblemsType = (problem_id) => (dispatch, getState) => {
    problemsApi.deleteProblemsType(getState().auth.AuthToken, problem_id).then(response => {
        CheckAndNotify(response, true)
        dispatch(getProblemsTypes())
    }).catch(error => {
        handleApiError(dispatch, error)
    })
}

export default problemsReducer