import Api from './Api'

class AuthApi extends Api {
    postRegister(email, password, password_confirmation, first_name, last_name,
                 country, shipments_per_month, phone, company,
                 invite_code, hear_about_us, accept_term_of_us) {
        return this.http.post('register',
            {
                email,
                password,
                password_confirmation,
                first_name,
                last_name,
                country,
                shipments_per_month,
                phone,
                company,
                invite_code,
                hear_about_us,
                accept_term_of_us
            })
    }

    postLogin(email, password) {
        return this.http.post('login',
            {
                email,
                password
            })
    }

    deleteLogout(AuthToken) {
        return this.http.delete('logout',
            {
                "token": AuthToken
            })
    }

    postPasswordForgot(email) {
        return this.http.post('password-forgot',
            {
                "email": email
            })
    }
}

export default new AuthApi()
