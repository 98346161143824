import {CalculatorApi} from '../../api'

import {fetchCalculateError, fetchCalculateSuccess, fetchCountrySuccess,
    CalculateLandingPageiInProgress, fetchCalculateLandingPageSuccess, fetchCalculateLandingPageValidationErrors, fetchCalculateLandingPageRemoveValidationError } from '../../actions/calculate'
import {CheckAndNotify, setCalculatorErrorList} from "../orders/ordersReducer"
import Notify from "../../../views/components/notify/Notify"

const USER_ERRORS = 'AUTH/SET-USER-REGISTRATION-ERRORS'

const initialState = {
    data: null,
    CalculateLandingData: null,
    error: null,
    countries: [],
    countriesError: null
}

function countryReducer(state, action) {
    switch (action.type) {
        case "FETCH_COUNTRY_SUCCESS":
            return {
                ...state,
                countries: action.payload
            }
        case "FETCH_COUNTRY_ERROR":
            return {
                ...state,
                countriesError: action.payload
            }
        default:
            return state
    }
}

function calculatorReducer(state, action) {
    switch (action.type) {
        case "FETCH_CALCULATE_SUCCESS":
            return {
                ...state,
                data: action.payload
            }
        case "FETCH_CALCULATE_ERROR":
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}

function calculateReducer(state = initialState, action) {
    switch (action.type) {
        case USER_ERRORS: // todo: move it to auth
            return {
                ...state
            }
        case "FETCH_CALCULATE_LANDING_PAGE_SUCCESS":
            return {
                ...state,
                CalculateLandingData: action.payload === null || undefined ? action.payload : Object.entries(action.payload)
            }
        case "FETCH_CALCULATE_LANDING_PAGE_VALIDATION_ERRORS":
            return {
                ...state,
                CalculateLandingValidationErrors: action.payload
            }
        case 'FETCH_CALCULATE_LANDING_PAGE_REMOVE_VALIDATION_ERROR':
            return {
                ...state,
                CalculateLandingValidationErrors: {...state.CalculateLandingValidationErrors, [action.key]: null}
            }
        case 'FETCH_CALCULATE_LANDING_PAGE_VALIDATION_ERROR':
            return {
                ...state,
                CalculateLandingValidationErrors: {...state.CalculateLandingValidationErrors, [action.field]: [action.error_name]}
            }
        case 'FETCH_CALCULATE_LANDING_PAGE_IN_PROGRESS':
            return {
                ...state,
                CalculateLandingInProgress: action.status
            }
        case "FETCH_CALCULATE_SUCCESS":
        case "FETCH_CALCULATE_ERROR":
            return calculatorReducer(state, action)
        case "FETCH_COUNTRY_SUCCESS":
        case "FETCH_COUNTRY_ERROR":
            return countryReducer(state, action)
        default:
            return state
    }
}


export const calculate = (AuthToken, country, address, city, postalCode, province,
                                      weight, weightUnit, dimensionLength, dimensionWidth,
                                      dimensionHeight, dimensionUnit) => (dispatch) => {
    CalculatorApi.postCalculatorShippingCost(AuthToken, country, address, city, postalCode, province,
        weight, weightUnit, dimensionLength, dimensionWidth, dimensionHeight, dimensionUnit)
        .then(response => {
            CheckAndNotify(response)
            return response.data
            dispatch(CalculateLandingPageiInProgress(false))
        })
        .then(body => {
            dispatch(fetchCalculateSuccess(body.data))
            dispatch(fetchCalculateLandingPageSuccess(body.data))
            dispatch(CalculateLandingPageiInProgress(false))
            Object.keys(body.data).forEach(key => {
                if (body.data[key].error_object?.length > 0) {
                    body.data[key].error_object?.forEach(
                        error => Notify(error, `${key} error `, 'error')
                    )
                }
            })
        })
        .catch(error => {
            dispatch(fetchCalculateError(error.response?.data))
            dispatch(CalculateLandingPageiInProgress(false))
        if (error.response) {
            // Request made and server responded
            if (error.response.data?.validation_errors) { dispatch(fetchCalculateLandingPageValidationErrors(error.response.data.validation_errors)) }
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    })
}

export const getAllCountries = (authToken) => (dispatch) => {
    CalculatorApi.getAllCountries(authToken)
        .then(response => {
            CheckAndNotify(response)
            return response.data
        })
        .then(body => {
            dispatch(fetchCountrySuccess(body.data))
        })
        .catch(error => {
            dispatch(fetchCalculateError(error.response?.data))
        })
}

export default calculateReducer

