import SettingsApi from '../../api/SettingsApi/SettingsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_SHIPPING_RULES_LIST,
    SET_SHIPPING_RULES_DETAILS,
    SET_ADD_RULE_ERRORS_LIST,
    REMOVE_ORDER_DECLARATION_ERROR,
    REMOVE_ADD_RULE_ERRORS_LIST,
    SET_CALCULATOR_ERROR,
    PUSH_CALCULATOR_ERROR,
    PUSH_SHIPPING_RULE_DETAIL,
    CLEAR_SHIPPING_RULE_DETAIL_ARRAY,
    PUSH_SHIPPING_RULE_DETAIL_ARRAY,
    SET_CUSTOM_DECLARATION_RULES_LIST,
    SET_CUSTOM_DECLARATION_RULES_DETAILS,
    SET_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST,
    REMOVE_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST, PUSH_ADD_RULE_ERROR, PUSH_ADD_CUSTOMS_DECLARATION_RULE_ERROR
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import {
    getShippingRulesDetailsArray,
    getShippingRulesList, setAddRule_ValidationErrorsList,
    setShippingRuleDetails,
    setShippingRulesList
} from "./shippingRulesReducer"

const initialState = {
    CustomsDeclarationRulesList: [],
    CustomsDeclarationRulesDetails: null
}

const customsDeclarationRulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CUSTOM_DECLARATION_RULES_LIST:
            return {
                ...state,
                CustomsDeclarationRulesList: action.array.sort((a, b) => a.rule_name.localeCompare(b.rule_name))
            }
        case SET_CUSTOM_DECLARATION_RULES_DETAILS:
            return {
                ...state,
                CustomsDeclarationRulesDetails: action.object
            }
        case SET_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST:
            return {
                ...state,
                AddCustomsDeclarationRule_ValidationErrorsList: action.array
            }
        case REMOVE_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST:
            return {
                ...state,
                AddCustomsDeclarationRule_ValidationErrorsList: {...state.AddCustomsDeclarationRule_ValidationErrorsList, [action.error_name]: null}
            }
        case PUSH_ADD_CUSTOMS_DECLARATION_RULE_ERROR:
            return {
                ...state,
                AddCustomsDeclarationRule_ValidationErrorsList: {...state.AddCustomsDeclarationRule_ValidationErrorsList, [action.error_name]: [action.error_value]}
            }
        default:
            return state
    }
}

export const setCustomsDeclarationRulesList = (array) => ({type: SET_CUSTOM_DECLARATION_RULES_LIST, array})
export const setCustomsDeclarationRulesDetails = (object) => ({type: SET_CUSTOM_DECLARATION_RULES_DETAILS, object})
export const setAddCustomsDeclarationRule_ValidationErrorsList = (array) => ({type: SET_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST, array})
export const removeAddCustomsDeclarationRule_ValidationErrorsList = (error_name) => ({type: REMOVE_ADD_CUSTOMS_DECLARATION_RULE_ERRORS_LIST, error_name})
export const pushAddCustomsDeclarationRule_ValidationError = (error_name, error_value) => ({type: PUSH_ADD_CUSTOMS_DECLARATION_RULE_ERROR, error_value, error_name})

export const getCustomsDeclarationRulesList = (conditions, RequestConditionsList) => (dispatch, getState) => {
    SettingsApi.customsDeclarationRulesList(getState().auth.AuthToken, conditions).then(response => {
        CheckAndNotify(response)
        const responseData = response.data.data.data
        dispatch(setCustomsDeclarationRulesList(responseData))
        /*if (RequestConditionsList) {
            let rules_list = getState().shippingRules.ShippingRulesList
            rules_list = rules_list.map(r => (r.id))
            if (rules_list) {
                dispatch(getShippingRulesDetailsArray(rules_list))
            }
        }*/

    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const changeStatusCustomsDeclarationRules = (status, ids) => (dispatch, getState) => {
    SettingsApi.customsDeclarationRulesChangeStatus(getState().auth.AuthToken, status, ids).then(response => {
        CheckAndNotify(response)
        dispatch(getCustomsDeclarationRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const deleteCustomsDeclarationRules = (ids) => (dispatch, getState) => {
    SettingsApi.customsDeclarationRulesDelete(getState().auth.AuthToken, ids).then(response => {
        dispatch(getCustomsDeclarationRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getCustomsDeclarationRulesDetails = (id) => (dispatch, getState) => {
    let OrderDetails = getState().customsDeclarationRules.CustomsDeclarationRulesList.find(r => r.id === id)
    dispatch(setCustomsDeclarationRulesDetails(OrderDetails))

}

export const createCustomsDeclarationRule = (rule_name, shop_id, country_id, status, description,
                                             country_of_origin, quantity, item_cost, total_value_from, total_value_to,
                                             hs_code, hs_code_value, sku, sku_value, product_category) => (dispatch, getState) => {
    setAddCustomsDeclarationRule_ValidationErrorsList([])
    SettingsApi.customsDeclarationRulesCreate(getState().auth.AuthToken, rule_name, shop_id, country_id, status, description,
        country_of_origin, quantity, item_cost, total_value_from, total_value_to,
        hs_code, hs_code_value, sku, sku_value, product_category).then(response => {
        dispatch(getCustomsDeclarationRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddCustomsDeclarationRule_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const updateCustomsDeclarationRule = (id, rule_name, shop_id, country_id, status, description,
                                             country_of_origin, quantity, item_cost, total_value_from, total_value_to,
                                             hs_code, hs_code_value, sku, sku_value, product_category) => (dispatch, getState) => {
    setAddCustomsDeclarationRule_ValidationErrorsList([])
    SettingsApi.customsDeclarationRulesUpdate(getState().auth.AuthToken, id, rule_name, shop_id, country_id, status, description,
        country_of_origin, quantity, item_cost, total_value_from, total_value_to,
        hs_code, hs_code_value, sku, sku_value, product_category).then(response => {
        dispatch(getCustomsDeclarationRulesList())
        Notify(response.data.message, 'Succes', 'success')
    }).catch(error => {
        if (error.response?.data?.validation_errors) { dispatch(setAddCustomsDeclarationRule_ValidationErrorsList(error.response.data.validation_errors)) }
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default customsDeclarationRulesReducer
