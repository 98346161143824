import Api from './Api'

class commonApi extends Api {
    downloadFile(fileURL) {
        this.http.get(fileURL, {
            headers: {
                'Content-Type': 'application/pdf',
                "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
            }
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob])
                )
                const link = document.createElement('a')
                link.href = url
                link.setAttribute(
                    'download',
                    `FileName.pdf`
                )

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)
            })
    }

    getCountries(token) {
        return this.http.get('manual/countries',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCalculatorServices(token) {
        return this.http.get('manual/delivery-service-types',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCalculatorConfirmations(token) {
        return this.http.get('manual/delivery-service-confirmation-types',
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    getCalculatorDeliveryPackages(token, delivery_service_type_id) {
        return this.http.post('manual/delivery-packages',
            {
                "delivery_service_type_id": delivery_service_type_id
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
    ContactUs(token, name, email, question) {
        return this.http.post('/landing-page/contact-us-form',
            {
                "name": name,
                "email": email,
                "question": question
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default new commonApi()