import DeliveredApi from '../../api/OrdersApi/DeliveredApi'
import Notify from "../../../views/components/notify/Notify"
import {CheckAndNotify} from "./ordersReducer"

const ORDERS_LIST = 'DELIVERED/SET-ORDERS-LIST'
const SET_FLOATING_VALUE = 'DELIVERED/SET_FLOATING_VALUE'
const SET_DETAILS_VIEW_INFO = 'DELIVERED/SET_DETAILS_VIEW_INFO'
const REMOVE_DETAILS_VIEW_INFO = 'DELIVERED/REMOVE_DETAILS_VIEW_INFO'
const SET_DETAILS_INFO_SELECTED = 'DELIVERED/SET_DETAILS_INFO_SELECTED'
const ADD_SELECTED_ROW = 'DELIVERED/ADD_SELECTED_ROW'
const REMOVE_SELECTED_ROW = 'DELIVERED/REMOVE_SELECTED_ROW'
const CLEAR_SELECTED_ROWS = 'DELIVERED/CLEAR_SELECTED_ROWS'
const SET_ORDERS_CONDITIONS = 'DELIVERED/SET_ORDERS_CONDITIONS'
const CLEAR_ORDERS_CONDITIONS = 'DELIVERED/CLEAR_ORDERS_CONDITIONS'
const SET_ORDERS_SORT_CONDITIONS = 'DELIVERED/ORDERS_SORT_CONDITIONS'
const UPDATE_ORDERS_CONDITIONS = 'DELIVERED/UPDATE_ORDERS_CONDITIONS'
const SET_DETAILS_VIEW_FULL_SCREEN = 'DELIVERED/SET_DETAILS_VIEW_FULL_SCREEN'
const SET_CURRENT_PAGE = 'DELIVERED/SET_CURRENT_PAGE'
const CLEAR_ORDERS_LIST = 'DELIVERED/CLEAR_ORDERS_LIST'

const initialState = {
    OrdersList_Awaiting: [],
    OrdersConditions_Awaiting: {},
    OrdersSortConditions_Awaiting: { sortingColumn: null, isInverted: null },
    currentPage: 1,
    PageSize: 50,
    OrdersListMessage: null,
    OrdersFloatingInputValues: {},
    DetailsView_Awaiting: {},
    DetailsViewSelected: false,
    SelectedRows: []
}

const awaitingForOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case ORDERS_LIST:
            return {
                ...state,
                OrdersList_Awaiting: /*[...state.OrdersList_Awaiting, ...action.order_list].filter((v, i, a) => a.findIndex(t => (t.order_id === v.order_id)) === i)*/ [...state.OrdersList_Awaiting, ...action.order_list]
            }
        case CLEAR_ORDERS_LIST:
            return {
                ...state,
                OrdersList_Awaiting: []
            }
        case SET_FLOATING_VALUE:
            return {
                ...state,
                OrdersFloatingInputValues: {...state.OrdersFloatingInputValues, [action.key]: action.value}
            }
        case SET_DETAILS_VIEW_INFO:
            return {
                ...state,
                DetailsView_Awaiting : {
                    ...state.DetailsView_Awaiting,
                    selected: action.selected,
                    data: action.data
                }
            }
        case SET_DETAILS_VIEW_FULL_SCREEN:
            return {
                ...state,
                DetailsView_Awaiting : {
                    ...state.DetailsView, isFullScreen: action.isFullScreen
                }
            }
        case REMOVE_DETAILS_VIEW_INFO:
            return {
                ...state,
                DetailsView_Awaiting : null
            }
        case SET_DETAILS_INFO_SELECTED:
            return {
                ...state,
                DetailsViewSelected : action.selected
            }
        case ADD_SELECTED_ROW:
            return {
                ...state,
                SelectedRows : [...state.SelectedRows, {id: action.id, data: action.data}]
            }
        case REMOVE_SELECTED_ROW:
            return {
                ...state,
                SelectedRows : state.SelectedRows.filter(row => row.id !== action.id)
            }
        case CLEAR_SELECTED_ROWS:
            return {
                ...state,
                SelectedRows : [],
                DetailsViewSelected: false
            }
        case SET_ORDERS_CONDITIONS:
            return {
                ...state,
                OrdersConditions_Awaiting: {...state.OrdersConditions_Awaiting, [action.condition_name]: {...state.OrdersConditions_Awaiting[action.condition_name], "filter": action.condition_value}},
                currentPage: 1,
                OrdersList_Awaiting: []
            }
        case CLEAR_ORDERS_CONDITIONS:
            return {
                ...state,
                OrdersConditions_Awaiting: {},
                currentPage: 1
            }
        case SET_ORDERS_SORT_CONDITIONS:
            return {
                ...state,
                OrdersSortConditions_Awaiting: {sortingColumn: action.sortingColumn, isInverted: action.isInverted},
                currentPage: 1
            }
        case UPDATE_ORDERS_CONDITIONS:
            return {
                ...state,
                OrdersConditions_Awaiting: {...state.OrdersConditions_Awaiting, [action.sortingColumn]: {...state.OrdersConditions_Awaiting[action.sortingColumn], "order_by": action.sort_by}},
                currentPage: 1,
                OrdersList_Awaiting: []
            }
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.currentPage > 0 ? action.currentPage : 1
            }
        default:
            return state
    }
}

const setOrdersList = (order_list) => ({type: ORDERS_LIST, order_list})
export const clearOrdersList = () => ({type: CLEAR_ORDERS_LIST})
export const setOrdersFloatingInputValues = (key, value) => ({type: SET_FLOATING_VALUE, key, value})
export const setDetailsViewInfo = (data, selected) => ({type: SET_DETAILS_VIEW_INFO, selected, data})
export const removeDetailsViewInfo = () => ({type: REMOVE_DETAILS_VIEW_INFO})
export const setDetailsViewFullScreen = (isFullScreen) => ({type: SET_DETAILS_VIEW_FULL_SCREEN, isFullScreen})
export const setDetailsViewSelected = (selected) => ({type: SET_DETAILS_INFO_SELECTED, selected})
export const addSelectedRow = (id, data) => ({type: ADD_SELECTED_ROW, id, data})
export const removeSelectedRow = (id) => ({type: REMOVE_SELECTED_ROW, id})
export const clearSelectedRow = () => ({type: CLEAR_SELECTED_ROWS})
export const setCurrentPage = (currentPage) => ({type: SET_CURRENT_PAGE, currentPage})
export const setOrdersConditions = (condition_name, condition_value) => ({
    type: SET_ORDERS_CONDITIONS, condition_name, condition_value
})
export const clearOrdersConditions = () => ({type: CLEAR_ORDERS_CONDITIONS})
export const setOrdersSortConditions = (sortingColumn, isInverted) => ({
    // set actual orders sort conditions
    type: SET_ORDERS_SORT_CONDITIONS, sortingColumn, isInverted
})
export const updateOrdersConditions = (sortingColumn, isInverted) => ({
    // after each sorting click we set our sort conditions into OrdersConditions object which should be send in API
    type: UPDATE_ORDERS_CONDITIONS, sortingColumn, sort_by: isInverted ? "DESC" : (isInverted === false ? "ASC" : null)
})

export const updateAndSetSortOrdersConditions = (sortingColumn, isInverted) => (dispatch) => {
    dispatch(setOrdersSortConditions(sortingColumn, isInverted))
    dispatch(updateOrdersConditions(sortingColumn, isInverted))
}

export const handleCurrentPageChange = (currentPage) => (dispatch, getState) => {
    if (currentPage > getState().delivered.currentPage) {
        dispatch(setCurrentPage(currentPage))
        dispatch(getOrdersList())
        return true
    }
    return false
}

export const getOrdersList = (limit, offset) => (dispatch, getState) => {
    DeliveredApi.postList(getState().auth.AuthToken,
        getState().orders.CurrentShop?.shop_id,
        getState().delivered.PageSize,
        getState().delivered.OrdersList_Awaiting.length,
        getState().delivered.OrdersConditions_Awaiting).then(response => {
        CheckAndNotify(response)
        dispatch(setOrdersList(response.data.data.data))
        if (getState().delivered.OrdersSortConditions_Awaiting.isInverted === true) {
            dispatch(setOrdersSortConditions(getState().delivered.OrdersSortConditions_Awaiting.sortingColumn, null))
        }
    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            if (getState().delivered.OrdersSortConditions_Awaiting.isInverted === true) {
                dispatch(setOrdersSortConditions(getState().delivered.OrdersSortConditions_Awaiting.sortingColumn, null))
            }
        } else if (error.request) {
            // The request was made but no response was received
            if (getState().delivered.OrdersSortConditions_Awaiting.isInverted === true) {
                dispatch(setOrdersSortConditions(getState().delivered.OrdersSortConditions_Awaiting.sortingColumn, null))
            }
        } else {
            // Something happened in setting up the request that triggered an Error
            if (getState().delivered.OrdersSortConditions_Awaiting.isInverted === true) {
                dispatch(setOrdersSortConditions(getState().delivered.OrdersSortConditions_Awaiting.sortingColumn, null))
            }
        }
    })
}

export const getOrderDetails = (order_id, selected) => (dispatch, getState) => {
    DeliveredApi.getDeliveredDetails(getState().auth.AuthToken, getState().orders.CurrentShop?.shop_id, order_id).then(response => {
        CheckAndNotify(response)
        dispatch(setDetailsViewInfo(response.data.data, selected))
    }).catch(error => {
        if (error.response) {
            // Request made and server responded
            Notify('', 'Error', 'error')
        } else if (error.request) {
            // The request was made but no response was received
            Notify('', 'Error', 'error')
        } else {
            // Something happened in setting up the request that triggered an Error
            Notify('', 'Error', 'error')
        }
    })
}

export default awaitingForOrdersReducer
