import PermissionsApi from '../../api/SettingsApi/PermissionsApi/PermissionsApi'
import Notify from "../../../views/components/notify/Notify"
import commonApi from "../../api/commonApi"
import {
    SET_PERMISSION_IS_CHECKED, SET_PERMISSIONS_LIST,
    SET_ROLES_PERMISSIONS_LIST, SET_SHIPPING_RULES_LIST, SET_USER_SHOPS, SET_USERS_VERSIONS_LIST, SET_VERSIONS_LIST
} from "../../constants/constants"
import {
    CheckAndNotify,
    CheckAndNotifyErrors,
    LogOutOnAuthError, setCalculatorErrorList
} from "../orders/ordersReducer"
import {getShippingRulesList} from "../settings/shippingRulesReducer"
import {Logout} from "../auth/authReducer"

export const Frontend_Permissions_Ids = [
    {"route_name": "/orders",
        "ids": {
            "download_report": [55],
            "awaiting_import_csv_button": [1],
            "order_import_txt_button": [34, 36],
            "add_manual_order_button": [33, 36],
            "export_labels_button": [26],
            "end_of_the_day_form_button": [27],
            "order_details_window": [45],
            "order_tab_new": [2],
            "order_tab_awaiting": [3],
            "order_tab_received": [8],
            "order_tab_print_late": [9],
            "order_tab_shipped": [7],
            "order_tab_canceled": [10],
            "all_order_tab": [11, 12, 16, 17, 15, 18],
            "support_create_label": [135]
        }
    },
    {"route_name": "/orders/details",
        "ids": {
            "order_details_edit_button": [35],
            "order_details_barcode_button": [50, 45],
            "order_details_change_status_button": [46, 45],
            "order_details_change_status_to_received_button": [],
            "order_details_change_status_to_return_button": [],
            "order_details_change_status_to_print_late_button": [],
            "order_details_change_status_to_shipped_button": [],
            "order_details_change_status_to_cancel_button": [],
            "order_details_edit_item_quantity": [47, 45],
            "order_details_create_order_declaration_auto": [41, 45],
            "order_details_create_order_declaration_button": [39, 45],
            "order_details_update_order_declaration_button": [40],
            "order_details_delete_order_declaration_button": [42, 45],
            "order_details_labels_button": [25],
            "order_details_labels_void_button": [24, 25],
            "order_details_labels_custom_declaration_button": [44, 25, 45],
            "order_details_internal": [20, 45],
            "order_details_create_label_button": [23],
            "order_details_create_label_button_without_scales_validation": [135],
            "order_details_chat_send_message_button": [29, 45],
            "order_details_chat_messages_list": [31, 45],
            "is_final_shipping_label":[156]
        }
    },
    {"route_name": "/upc-info",
        "ids": {
            "upc-info_open_page_icon": [51],
            "upc-info_auto_create_declaration": [41],
            "upc-info_internal": [20, 45],
            "upc-info_scan_page_view_search_area": [51],
            "upc-info_print_barcode_button": [50],
            "upc-info_problem_button": [49],
            "upc-info_timer": [52]
        }
    },
    {"route_name": "/work-log",
        "ids": {
            "work-log": [105],
            "order-log": [106]
        }
    },
    {"route_name": "/settings",
        "ids": {
            "settings-page": [74, 59, 78, 64, 103, 109]
        }
    },
    {"route_name": "/settings/shipping-rules",
        "ids": {
            "shipping-rules-page": [74],
            "shipping-rules_add_rule_button": [72],
            "shipping-rules_edit_rule_button": [74]
        }
    },
    {"route_name": "/settings/customs-declaration-rules",
        "ids": {
            "customs-declaration-rules_page": [59],
            "customs-declaration-rules_add_rule_button": [60],
            "customs-declaration-rules_edit_rule_button": [61]
        }
    },
    {"route_name": "/settings/permissions/users",
        "ids": {
            "permissions_users_page": [78],
            "permissions_users_add_user_button": [77],
            "permissions_users_edit_user_button": [78],
            "permissions_users_delete_user_button": [79]
        }
    },
    {"route_name": "/settings/permissions/roles",
        "ids": {
            "permissions_roles_page": [64]
        }
    },
    {"route_name": "/settings/permissions/versions",
        "ids": {
            "versions_page": [103]
        }
    },
    {"route_name": "/settings/permissions/shops",
        "ids": {
            "shops_page": [109]
        }
    }
]

export const initialState = {
    Roles_PermissionsList: [],
    Permissions: [],
    Frontend_Permissions_Ids: Frontend_Permissions_Ids,
    VersionsList: [],
    UsersVersions: [],
    UserShops: []
}

const permissionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ROLES_PERMISSIONS_LIST:
            return {
                ...state,
                Roles_PermissionsList: action.array/*.sort(function(a, b) {
                    return (((b.updated_at ? new Date(b.updated_at) : null) || new Date(b.created_at)) - ((a.updated_at ? new Date(a.updated_at) : null) || new Date(a.created_at)))
                })*/
            }
        case SET_PERMISSION_IS_CHECKED:
            let index = state.Roles_PermissionsList.findIndex(item => Number(item.permission_group_id) === Number(action.id))
            let newPermissionList = [...state.Roles_PermissionsList]
            newPermissionList[index].checked = action.boolean
            return {
                ...state,
                Roles_PermissionsList: newPermissionList
            }
        case SET_PERMISSIONS_LIST:
            return {
                ...state,
                Permissions: action.list
            }
        case SET_VERSIONS_LIST:
            return {
                ...state,
                VersionsList: action.list
            }
        case SET_USERS_VERSIONS_LIST:
            return {
                ...state,
                UsersVersions: action.list
            }
        case SET_USER_SHOPS:
            return {
                ...state,
                UserShops: action.shopsList
            }
        default:
            return state
    }
}

export const setRolesPermissions = (array) => ({type: SET_ROLES_PERMISSIONS_LIST, array})
export const setPermissionIsChecked = (id, boolean) => ({type: SET_PERMISSION_IS_CHECKED, id, boolean})
export const setPermissions = (list) => ({type: SET_PERMISSIONS_LIST, list})
export const setVersionsList = (list) => ({type: SET_VERSIONS_LIST, list})
export const setUsersVersionsList = (list) => ({type: SET_USERS_VERSIONS_LIST, list})
export const setUserShops = (shopsList) => ({type: SET_USER_SHOPS, shopsList})

export const getRolesPermissionsList = () => (dispatch, getState) => {
    PermissionsApi.getRolesPermissionsList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setRolesPermissions(response.data.data.data))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getPermissions = () => (dispatch, getState) => {
    PermissionsApi.getPermissions(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(setPermissions(response.data.data.permission_id))
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getCurrentVersionAndCompare = (app_version) => (dispatch, getState) => {
    PermissionsApi.getVersion(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        if (Number(app_version) === Number(response.data.data.version_id)) {

        } else {
            dispatch(SwitchVersion(response.data.data.version_id))
        }
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export const SwitchVersion = (version_id) => (dispatch, getState) => {
    PermissionsApi.getVersionsList(getState().auth.AuthToken).then(response => {
        CheckAndNotify(response)
        dispatch(Logout(getState().auth.AuthToken))
        let url = response.data.data.data.find(v => Number(v.version_id) === Number(version_id)).url
        window.location.replace(url)
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export const getVersionsList = () => (dispatch, getState) => {
    PermissionsApi.getVersionsList(getState().auth.AuthToken).then(response => {
        dispatch(setVersionsList(response.data.data.data))
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            CheckAndNotifyErrors(error)
        }
    })
}

export const editUserVersion = (user_id, version_id, setIsFetching) => (dispatch, getState) => {
    setIsFetching(true)
    PermissionsApi.editUserVersion(getState().auth.AuthToken, user_id, version_id).then(response => {
        dispatch(getUsersVersions())
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    }).finally(() => {
        setIsFetching(false)
    })
}

export const getUsersVersions = () => (dispatch, getState) => {
    PermissionsApi.getUsersVersions(getState().auth.AuthToken).then(response => {
        dispatch(setUsersVersionsList(response.data.data))
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const getUserShops = () => (dispatch, getState) => {
    PermissionsApi.getUserShops(getState().auth.AuthToken).then(response => {
        dispatch(setUserShops(response.data.data))
        CheckAndNotify(response)
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export const editUserShops = (user_id, shop_ids) => (dispatch, getState) => {
    PermissionsApi.editUserShops(getState().auth.AuthToken, user_id, shop_ids).then(response => {
        dispatch(getUserShops())
        CheckAndNotify(response)
        Notify(response.data.message, 'Success', 'success')
    }).catch(error => {
        if (error) {
            dispatch(LogOutOnAuthError(error))
            CheckAndNotifyErrors(error)
        }
    })
}

export default permissionsReducer
