import Api from '../Api'

class DeliveredApi extends Api {
    postList(token, shop_id, limit, offset, conditions, responseType) {
            if (shop_id === "all") {
            return this.http.post('all-order-tab/delivered',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.sessionStorage.getItem('AuthDeliKey')
                    }
                }
            )

        } else {
            return this.http.post('order-tab/delivered',
                {
                    "limit": responseType ? offset  : (limit ? limit : 50),
                    "offset": responseType ? 0 : (offset ? offset : 0),
                    "conditions": conditions ? conditions : null,
                    "shop_id": shop_id ? shop_id : 1,
                    "response_type": responseType ? responseType : null
                },
                {
                    headers: {
                        "Token": token ? token : window.sessionStorage.getItem('AuthDeliKey')
                    }
                }
            )
        }
    }

    getDeliveredDetails(token, shop_id, order_id) {
        return this.http.post('order/detail/delivered',
            {
                "order_id": order_id,
                "shop_id": shop_id ? shop_id : 1
            },
             {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postDeliveredDetailsEdit(token, order_id, name, phone, country_id, address_line1, address_line2, address_line3, city, state_or_region, postal_code, shipping_price, tax_amount) {
        return this.http.post('order/details/edit',
            {
                "order_id": order_id,
                "name": name,
                "phone": phone,
                "country_id": country_id,
                "address_line1": address_line1,
                "address_line2": address_line2,
                "address_line3": address_line3,
                "city": city,
                "state_or_region": state_or_region,
                "postal_code": postal_code,
                "shipping_price": shipping_price,
                "tax_amount": tax_amount
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }

    postDeliveredCreateDeclaration(token, order_id, asin, title, packaged_quantity, country_id, item_value, hs_code) {
        return this.http.post('order/details/create-declaration',
            {
                "order_id": order_id,
                "asin": asin,
                "title": title,
                "packaged_quantity": packaged_quantity,
                "country_id": country_id,
                "item_value": item_value,
                "hs_code": hs_code
            },
            {
                headers: {
                    "Token": token ? token : window.localStorage.getItem('AuthDeliKey')
                }
            }
        )
    }
}

export default new DeliveredApi()