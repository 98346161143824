import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './notify.scss'

function Succes({ message, name }) {
    return (
        <div className={'ContentWrapper'}>
            <div className="Succes">
                <SuccesSVG/>&nbsp;{name}
            </div>
            <div className={'EscSVGsuccess'}>
                <EscSVG/>
            </div>
            <div className={'MessageSuccess'}>
                {message}
            </div>
        </div>
    )
}
function Error({ message, name }) {
    return (
        <div className={'ContentWrapper'}>
            <div className="Error">
                <ErrorSVG/>&nbsp;{name}
            </div>
            <div className={'EscSVG'}>
                <EscSVG/>
            </div>
            <div className={'Message'}>
                {message}
            </div>
        </div>
    )
}

function Info({ message, name }) {
    return (
        <div className={'ContentWrapper'}>
            <div className="Info">
                <ErrorSVG/>&nbsp;{name}
            </div>
            <div className={'EscSVG'}>
                <EscSVG/>
            </div>
            <div className={'Message'}>
                {message}
            </div>
        </div>
    )
}

function Warning({ message, name }) {
    return (
        <div className={'ContentWrapper'}>
            <div className="Warning">
                <ErrorSVG/>&nbsp;{name}
            </div>
            <div className={'EscSVG'}>
                <EscSVG/>
            </div>
            <div className={'Message'}>
                {message}
            </div>
        </div>
    )
}

function Notify(message, name, type, autoClose = 6000) {
    // type = success / error / info / warning
    const notify = (message) => toast(type === 'success' ? <Succes message={message} name={name}/> : (type === 'error' ? <Error message={message} name={name}/> : (type === 'info' ? <Info message={message} name={name}/> : (type === 'warning' ? <Warning message={message} name={name}/> : null))), {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: 'toast-' + type,
        toastClassName: 'toast',
        bodyClassName: 'body',
        closeButton: false
    })

    return (
        notify(message)

    )
}

const SuccesSVG = () => {
    return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.6666 7.38625V7.99958C14.6658 9.4372 14.2003 10.836 13.3395 11.9875C12.4787 13.1389 11.2688 13.9812 9.89016 14.3889C8.51154 14.7965 7.03809 14.7475 5.68957 14.2493C4.34104 13.7511 3.18969 12.8303 2.40723 11.6243C1.62476 10.4183 1.25311 8.99163 1.3477 7.55713C1.44229 6.12263 1.99806 4.75714 2.93211 3.6643C3.86615 2.57146 5.12844 1.80984 6.53071 1.49301C7.93298 1.17619 9.4001 1.32114 10.7133 1.90625" stroke="#86C64F" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M14.6667 2.66699L8 9.34033L6 7.34033" stroke="#86C64F" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
    )
}

const ErrorSVG = () => {
    return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6819 14.6666 7.99998C14.6666 4.31808 11.6818 1.33331 7.99992 1.33331C4.31802 1.33331 1.33325 4.31808 1.33325 7.99998C1.33325 11.6819 4.31802 14.6666 7.99992 14.6666Z" stroke="#FF3541" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8 5.33331V7.99998" stroke="#FF3541" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8 10.6667H8.00667" stroke="#FF3541" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        )
}

const EscSVG = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4L4 12" stroke="#221616" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 4L12 12" stroke="#221616" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export default Notify