import commonApi from '../../api/commonApi'
import Notify from "../../../views/components/notify/Notify"
import {setDetailsViewInfo} from "../orders/deliveredReducer"
import {CheckAndNotify} from "../orders/ordersReducer"
import {
    PUSH_ADD_USER_ERROR, PUSH_INPUT_ERROR, PUSH_INPUT_ERRORS_LIST,
    REMOVE_ADD_USER_ERRORS_LIST, REMOVE_INPUT_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST,
    SET_INPUT_ERRORS_LIST
} from "../../constants/constants"
const SET_COUNTACT_US_VALIDATION_ERRORS = 'COMMON/SET_COUNTACT_US_VALIDATION_ERRORS'
const REMOVE_COUNTACT_US_VALIDATION_ERROR = 'COMMON/REMOVE_COUNTACT_US_VALIDATION_ERROR'
const DISABLE_ASK_QUESTION_BUTTON = 'COMMON/DISABLE_ASK_QUESTION_BUTTON'

const initialState = {
    AskQuestionDisabled: false,
    InputErrorsList: []

}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COUNTACT_US_VALIDATION_ERRORS:
            return {
                ...state,
                ContactUsValidationErrors: action.errors
            }
        case REMOVE_COUNTACT_US_VALIDATION_ERROR:
            return {
                ...state,
                ContactUsValidationErrors: {...state.ContactUsValidationErrors, [action.key]: null}
            }
        case DISABLE_ASK_QUESTION_BUTTON:
            return {
                ...state,
                AskQuestionDisabled: action.boolean
            }
        case SET_INPUT_ERRORS_LIST:
            return {
                ...state,
                InputErrorsList: action.array
            }
        case REMOVE_INPUT_ERRORS_LIST:
            return {
                ...state,
                InputErrorsList: {...state.InputErrorsList, [action.error_name]: null}
            }
        case PUSH_INPUT_ERROR:
            return {
                ...state,
                InputErrorsList: {...state.InputErrorsList, [action.error_name]: [action.error_value]}
            }
        default:
            return state
    }
}

export const setContactUsValidationErrors = (errors) => ({type: SET_COUNTACT_US_VALIDATION_ERRORS, errors})
export const removeContactUsValidationErrors = (key) => ({type: REMOVE_COUNTACT_US_VALIDATION_ERROR, key})
export const DisableAskQuestionButton = (boolean) => ({type: DISABLE_ASK_QUESTION_BUTTON, boolean})
export const setInputErrorsList = (array) => ({type: SET_INPUT_ERRORS_LIST, array})
export const removeInputErrorsList = (error_name) => ({type: REMOVE_INPUT_ERRORS_LIST, error_name})
export const pushInputError = (error_name, error_value) => ({type: PUSH_INPUT_ERROR, error_value, error_name})


export const ContactUs = (name, email, question) => (dispatch, getState) => {
    commonApi.ContactUs(getState().auth.AuthToken, name, email, question).then(response => {
        CheckAndNotify(response)
        /*if (response.data.message) {
            Notify(response.data.message, 'Succes', 'succes')
        }*/
        if (response.data.status === "OK") {
            dispatch(DisableAskQuestionButton(true))
            Notify('Thank you for contacting us! One of our customer support specialists will get back to you within 24 hours. Thank you!', 'Succes', 'succes')
        }
    }).catch(error => {
        if (error.response) {
            if (error.response.data.message) {
                Notify(error.response.data.message, 'Error', 'error')
            }
            if (error.response.data.validation_errors) {
                // Request made and server responded
                dispatch(setContactUsValidationErrors(error.response.data.validation_errors))
            }
        }
    })
}

export default commonReducer