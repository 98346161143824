import {SetErrorCount} from "../reducers/settings/AppErrorReducer"
import {blockUser} from "../reducers/settings/UsersReducer"
import {
    PUSH_ADD_USER_ERROR,
    REMOVE_ADD_USER_ERRORS_LIST,
    SET_ADD_USER_ERRORS_LIST,
    SET_USER_LIST
} from "../constants/constants"

export const AppErrorHandler = (store) => (next) => (action) => {

    const block = (err_name, err_count) => {

        const app_error = Object.assign({}, store.getState().AppErrorReducer.AppError)

        if (app_error[err_name].count >= err_count) {
            setTimeout(() => {
                store.dispatch(blockUser(store.getState().auth.UserInfo.user.user_id, app_error[err_name].message))
                store.dispatch(SetErrorCount('ResetScalesErrors', 0))
            }, 500)
        }
    }

    switch (action.type) {
        case 'ERROR/SET_ERROR_COUNT':
            block('SameScalesData', 4)
            block('SameScalesParam', 4)
            break
        default:
            break
    }

    const result = next(action)
    return result
}